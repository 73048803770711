/** @format */

import React from "react";

const Hire = () => {
  return (
    <>
      <div className='hire_sec'>
        <div className='container'>
          <h5>Why Hire</h5>
          <h2>Multimedia Animation Experts from Pakistan</h2>
          <p style={{ textAlign: "center", lineHeight: "25px" }}>
            Hiring locally can be financially challenging for small and medium
            businesses, especially considering the cost of Multimedia Animation
            experts and professionals. Outsourcing Multimedia Animation to
            Pakistan offers a solution, bridging the gap between your budget
            constraints and your business goals.
          </p>
          <div className='row mt-5'>
            <div className='col-md-4 mt-3'>
              <div className='card_img d-flex justify-content-center'>
                <img
                  src='../assets/images/dollar.svg'
                  style={{ width: "30%" }}
                  alt=''
                />
              </div>
              <h4>Massive Cost Savings</h4>
              <p style={{ textAlign: "center", lineHeight: "25px" }}>
                Cost-effectiveness is the key reason why hiring Multimedia
                Animation experts in Pakistan positions you in a win-win
                situation. You receive top-notch quality at a significantly
                lower cost.
              </p>
            </div>
            <div className='col-md-4 mt-3'>
              <div className='card_img d-flex justify-content-center'>
                <img
                  src='../assets/images/star.svg'
                  style={{ width: "30%" }}
                  alt=''
                />
              </div>
              <h4>Vast Talent Pool</h4>
              <p style={{ textAlign: "center", lineHeight: "25px" }}>
                Pakistan stands as the world's No. 1 outsourcing hub, boasting
                an extensive technical talent pool. You can hire Multimedia
                Animation experts based on your specific needs
              </p>
            </div>
            <div className='col-md-4 mt-3'>
              <div className='card_img d-flex justify-content-center'>
                <img
                  src='../assets/images/clock.svg'
                  style={{ width: "30%" }}
                  alt=''
                />
              </div>
              <h4>Time Zone Advantage</h4>
              <p style={{ textAlign: "center", lineHeight: "25px" }}>
                Outsourcing firms in Pakistan , such as VE, operate 24x7 to
                ensure timely delivery of work. This makes it convenient across
                time zones to engage Multimedia Animation experts offshore.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hire;
