/** @format */

import React, { useState } from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";

const data = [
  {
    id: 1,
    head: "What's the turnaround time to kickstart the process?",
    para: "Expect us to align the perfect developer with your company within days, not weeks or months. We'll introduce a selection of relevant, pre-screened candidates, and the final decision is always in your hands.",
  },
  {
    id: 2,
    head: "What technical proficiency does Virtual Mate bring to the table?",
    para: "Our network of seasoned developers encompasses over 400 highly sought-after skills. From back-end developers proficient in PHP, .NET, C#, Python, Ruby, Golang, and Java to front-end developers skilled in React.js, Vue.js, and Angular, we cover a diverse range. Additionally, we collaborate with designers, QAs, DevOps Engineers, Data Scientists, Data Engineers specializing in Apache Spark, Scala, Python, Machine Learning Engineers, and Business Intelligence experts in Qlik Sense, QlikView, and MS Power BI, among others.",
  },
  {
    id: 3,
    head: "How can Virtual Mate ensure it's the developer my company needs?",
    para: "In essence, our exhaustive recruitment and vetting process sets us apart. We delve deep into understanding our clients' requirements before embarking on the quest to find and propose the right developer. Only 2% of the 5000+ monthly applicants successfully navigate our stringent vetting process, which includes rigorous testing of both hard and soft skills using cutting-edge software, assessments of English proficiency and communication skills, and in-depth technical interviews. This meticulous approach guarantees that you'll be presented with a senior, reliable developer who we are confident will be the perfect fit for your company.",
  },
  {
    id: 4,
    head: "Does Virtual Mate oversee project leadership?",
    para: "While Virtual Mate is readily available to provide guidance for both developers and clients throughout a project, we don't take on the role of project leading in the traditional agency sense. Our approach is based on fostering a robust working relationship between the client and developer, as we believe direct collaboration strengthens this bond and enhances productivity. Every developer recruited by Virtual Mate is highly independent, communicative, and seamlessly integrates into your team.",
  },
  {
    id: 5,
    head: "Do the developers speak English?",
    para: "Certainly, all our developers are fluent in English, and they excel in communication skills. Proficiency in English is a crucial prerequisite for us as we seek to attract top-tier developers. This competency is a fundamental aspect of our vetting process. Additionally, our talent acquisition team conducts thorough interviews to assess whether a candidate is not only technically skilled but also a good cultural fit for a Western tech team.",
  },
];

const Questions = () => {
  const [showMore, setShowMore] = useState(-1);

  const toggleShowMore = index => {
    console.log(index);
    if (showMore === index) {
      setShowMore(-1);
    } else {
      setShowMore(index);
    }
  };

  return (
    <>
      <div className='questions_sec'>
        <div className='container'>
          <div className='d-flex justify-content-center'>
            <div className='border_top'></div>
          </div>
          <h2>Frequently Asked Questions</h2>

          <div className='row mt-5'>
            {data.map((res, index) => {
              return (
                <div className='col-md-6 bordr' key={res.id}>
                  <div className='d-flex justify-content-between align-items-center'>
                    <h5>{res.head}</h5>

                    {showMore === index ? (
                      <AiOutlineMinus
                        className='icon'
                        onClick={() => toggleShowMore(index)}
                        size={22}
                      />
                    ) : (
                      <AiOutlinePlus
                        className='icon'
                        onClick={() => toggleShowMore(index)}
                        size={22}
                      />
                    )}
                  </div>
                  <div className='mt-3'>
                    {showMore === index ? <p>{res.para}</p> : null}
                  </div>
                  <hr className='' />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Questions;
