/** @format */

import React from "react";

const Expert = () => {
  return (
    <>
      <div className='expert_sec'>
        <div className='bgImg'>
          <div className='text_bg'>
            <h5> Engage Design Experts for All Your</h5>
            <h2>Professional Design Needs</h2>
          </div>
        </div>
        <div className='container'>
          <div className='row mt-5'>
            <div className='col-md-4 mt-3'>
              <div className='card_img'>
                <img src='../assets/images/p1.svg' alt='' />
              </div>
              <h4>Graphic Design</h4>
            </div>
            <div className='col-md-4 mt-3'>
              <div className='card_img'>
                <img src='../assets/images/p2.svg' alt='' />
              </div>
              <h4>Web Design</h4>
            </div>
            <div className='col-md-4 mt-3'>
              <div className='card_img'>
                <img src='../assets/images/p3.svg' alt='' />
              </div>
              <h4>Front End Developer</h4>
            </div>
            <div className='col-md-4 mt-3'>
              <div className='card_img'>
                <img src='../assets/images/p4.svg' alt='' />
              </div>
              <h4>UI/UX Design</h4>
            </div>
            <div className='col-md-4 mt-3'>
              <div className='card_img'>
                <img src='../assets/images/p5.svg' alt='' />
              </div>
              <h4>Logo Design</h4>
            </div>
            <div className='col-md-4 mt-3'>
              <div className='card_img'>
                <img src='../assets/images/p6.svg' alt='' />
              </div>
              <h4>Photoshop Expert</h4>
            </div>

            <div className='col-md-4 mt-3'>
              <div className='card_img'>
                <img src='../assets/images/adobe-indesign.svg' alt='' />
              </div>
              <h4>InDesign Expert</h4>
            </div>
            <div className='col-md-4 mt-3'>
              <div className='card_img'>
                <img src='../assets/images/adobe-illustrator.svg' alt='' />
              </div>
              <h4>Illustration Expert</h4>
            </div>
            <div className='col-md-4 mt-3'>
              <div className='card_img'>
                <img src='../assets/images/image-ui.svg' alt='' />
              </div>
              <h4>Image Editing</h4>
            </div>
            <div className='col-md-4 mt-3'>
              <div className='card_img'>
                <img src='../assets/images/Newsletter.svg' alt='' />
              </div>
              <h4>Email Newsletter Template Design</h4>
            </div>
            <div className='col-md-4 mt-3'>
              <div className='card_img'>
                <img src='../assets/images/website.svg' alt='' />
              </div>
              <h4>Website Redesign</h4>
            </div>
            <div className='col-md-4 mt-3'>
              <div className='card_img'>
                <img src='../assets/images/postcard-stamp.svg' alt='' />
              </div>
              <h4>Business Cards, Postcards, Brochures</h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Expert;
