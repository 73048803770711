/** @format */

import React from "react";

const data = [
  {
    id: 1,
    image: "../assets/images/account-avatar.svg",
    head: "Dedicated Model",
    para: "You are assigned one Finance & Accounts expert exclusively. Gain complete control over your employee and project.",
  },
  {
    id: 2,
    image: "../assets/images/team-work.svg",
    head: "Team Model",
    para: "Now, you can assemble a team with diverse skill sets but only pay for the equivalent of a single resource.",
  },
  {
    id: 3,
    image: "../assets/images/time-complexity.svg",
    head: "Full-time, Part-time or Ad-hoc",
    para: "Depending on the amount of work, you can go for an 8-hour/day, 4-hour/day or ad hoc Finance & Accounts work.",
  },
];

const Choose = () => {
  return (
    <>
      <div className='choose_sec'>
        <div className='bg_img'></div>
        <div className='text_sec'>
          <h2> Choose From Our 3 Easy Hiring Models</h2>
          <div className='d-flex justify-content-center'>
            <h5>
              Hire Finance & Accounts experts on monthly, part-time, hourly or
              fixed cost basis
            </h5>
          </div>
        </div>
        <div className='container'>
          <div className='row mt-5'>
            {data.map(res => {
              return (
                <div key={res.id} className='col-md-4 mt-5'>
                  <div className='img_main'>
                    <img src={res.image} alt='' />
                  </div>
                  <h4>{res.head}</h4>
                  <p>{res.para}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div className='bg_img2'></div>
      </div>
    </>
  );
};

export default Choose;
