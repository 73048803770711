/** @format */

import React from "react";
import ContactForms from "../../Utils/ContactForms";

const Hero = () => {
  return (
    <>
      <div className='frontend_hero'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-7 d-flex flex-column justify-content-center '>
              <h2>Transform Your Business with Cutting-Edge IT Solutions! </h2>
              <h4>
                Starting at Just $8/Hour – Harness the Power of Skilled IT
                Experts
              </h4>
              <h4>
                Outsource IT Services to Pakistan and Elevate Your Operations
              </h4>
            </div>
            <div className='col-md-5'>
              <ContactForms />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
