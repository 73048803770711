/** @format */

import React from "react";

const Hire = () => {
  return (
    <>
      <div className='hire_sec'>
        <div className='container'>
          <h5>Supercharge Your Sales</h5>
          <h2>
            {" "}
            Unlock 10X Growth with Our Comprehensive Digital Marketing Services
          </h2>
          <p>
            With internet users dedicating 6 hours daily to digital media
            consumption, the key is converting these online enthusiasts into
            paying customers. Explore the option of hiring a digital marketing
            employee from a leading online marketing outsourcing company in
            Pakistan. Transform your website into a pivotal marketing asset,
            functioning as a continuous 24/7 online sales representative for
            your business.
          </p>
          <div className='row mt-5'>
            <div className='col-md-4 mt-3'>
              <div className='card_img d-flex justify-content-center'>
                <img
                  src='../assets/images/o1.svg'
                  style={{ width: "25%" }}
                  alt=''
                />
              </div>
              <h4>Search Engine Optimization</h4>
              <p
                style={{
                  textAlign: "justify",
                  lineHeight: "25px",
                  marginTop: "10px",
                }}>
                Covering everything from keyword research and technical audits
                to outreach campaigns and on-page optimization, our certified
                SEO 2.0 experts can enhance your website's online presence
                significantly.
              </p>
            </div>
            <div className='col-md-4 mt-3'>
              <div className='card_img d-flex justify-content-center'>
                <img
                  src='../assets/images/o2.svg'
                  style={{ width: "25%" }}
                  alt=''
                />
              </div>
              <h4>Pay-Per-Click</h4>
              <p
                style={{
                  textAlign: "justify",
                  lineHeight: "25px",
                  marginTop: "10px",
                }}>
                Boost Click-Through-Rates (CTRs) and elevate your Google quality
                scores. Ensure every click maximizes its value with our
                certified PPC experts in Google Ads and Bing Ads.
              </p>
            </div>
            <div className='col-md-4 mt-3'>
              <div className='card_img d-flex justify-content-center'>
                <img
                  src='../assets/images/o3.svg'
                  style={{ width: "25%" }}
                  alt=''
                />
              </div>
              <h4>Social Media Marketing</h4>
              <p
                style={{
                  textAlign: "justify",
                  lineHeight: "25px",
                  marginTop: "10px",
                }}>
                Enhance your visibility, grab attention, and generate buzz!
                Cultivate customer loyalty and establish brand presence across
                leading social media platforms with our experts in Social Media
                Marketing (SMM).
              </p>
            </div>
            <div className='col-md-4 mt-3'>
              <div className='card_img d-flex justify-content-center'>
                <img
                  src='../assets/images/o4.svg'
                  style={{ width: "25%" }}
                  alt=''
                />
              </div>
              <h4>Content Marketing</h4>
              <p
                style={{
                  textAlign: "justify",
                  lineHeight: "25px",
                  marginTop: "10px",
                }}>
                Amplify visibility, drive traffic, and boost sales. Our content
                marketing experts meticulously optimize every piece of content
                to increase traffic and generate valuable leads for your
                business.
              </p>
            </div>
            <div className='col-md-4 mt-3'>
              <div className='card_img d-flex justify-content-center'>
                <img
                  src='../assets/images/o5.svg '
                  style={{ width: "25%" }}
                  alt=''
                />
              </div>
              <h4>Email Marketing</h4>
              <p
                style={{
                  textAlign: "justify",
                  lineHeight: "25px",
                  marginTop: "10px",
                }}>
                Craft compelling emails that captivate customers. Expand your
                reach to a broader audience through our advanced email marketing
                solutions, complemented by regular performance analysis and
                campaign tracking.
              </p>
            </div>
            <div className='col-md-4 mt-3'>
              <div className='card_img d-flex justify-content-center'>
                <img
                  src='../assets/images/o6.svg'
                  style={{ width: "25%" }}
                  alt=''
                />
              </div>
              <h4>App Store Optimization</h4>
              <p
                style={{
                  textAlign: "justify",
                  lineHeight: "25px",
                  marginTop: "10px",
                }}>
                While creating an app is straightforward, gaining visibility
                among millions of existing apps is a challenge. Propel your
                brand visibility and ensure your app stands out by hiring ASO
                experts from VM
              </p>
            </div>

            <div className='col-md-4 mt-3'>
              <div className='card_img d-flex justify-content-center'>
                <img
                  src='../assets/images/video-marketing.svg'
                  style={{ width: "25%" }}
                  alt=''
                />
              </div>
              <h4>Video Marketing </h4>
              <p
                style={{
                  textAlign: "justify",
                  lineHeight: "25px",
                  marginTop: "10px",
                }}>Experience robust brand building, engage customers effectively, and outshine your competitors with a strategically optimized online presence on YouTube, Vimeo, and Metacafe, courtesy of our video optimization experts.</p>
            </div>

            <div className='col-md-4 mt-3'>
              <div className='card_img d-flex justify-content-center'>
                <img
                  src='../assets/images/google-analytics.svg'
                  style={{ width: "25%" }}
                  alt=''
                />
              </div>
              <h4>Google Analytics</h4>
              <p
                style={{
                  textAlign: "justify",
                  lineHeight: "25px",
                  marginTop: "10px",
                }}>Gain valuable business insights to deepen your understanding of customers. Our experts will assist you in measuring advertising ROI, tracking video performance, and monitoring social networking sites and applications to inform strategic business actions.</p>
            </div>

            <div className='col-md-4 mt-3'>
              <div className='card_img d-flex justify-content-center'>
                <img
                  src='../assets/images/affiliate-marketing.svg'
                  style={{ width: "25%" }}
                  alt=''
                />
              </div>
              <h4>Affiliate Marketing</h4>
              <p
                style={{
                  textAlign: "justify",
                  lineHeight: "25px",
                  marginTop: "10px",
                }}>Our affiliate marketing experts craft campaigns with a singular goal: to deliver tangible results for your online business. VM's specialists meticulously plan affiliate programs, ensuring you pay solely for genuine leads.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hire;
