import React from 'react'
import Hero from '../components/Elements/Designer/Hero'
import Design from '../components/Elements/Designer/Design'
import Expert from '../components/Elements/Designer/Expert'
import Hire from '../components/Elements/Designer/Hire'
import Professional from '../components/Elements/Designer/Professional'
import Difference from '../components/Elements/Designer/Difference'

const GraphicDesigner = () => {
  React.useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    scrollToTop();
  }, []);
  return (
    <div>
        <Hero/>
        <Design/>
        <Expert/>
        <Hire/>
        <Difference/>
        <Professional/>
    </div>
  )
}

export default GraphicDesigner