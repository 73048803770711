import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

const BlockTabs = () => {
  return (
    <>
      <div className="tabs_sec">
        <div className="container">
          <h4>From Translating Your Technical Specifications to Addressing Unique Business Requirements</h4>
          <h2>VM Has your covered</h2>
          <Tabs>
            <TabList>
              <div className="d-flex justify-content-center align-items-center flex-wrap gap-3 mt-5">
                <Tab>
                  <button>Free Trial</button>
                </Tab>
                <Tab>
                  <button>Free Recruitment</button>
                </Tab>
                <Tab>
                  {" "}
                  <button>Zero Overheads</button>
                </Tab>
                <Tab>
                  <button>Data Security</button>
                </Tab>
              </div>
            </TabList>
            <TabPanel>
              <div className="row card_main  mt-4">
                <div className="col-md-6 mt-3">
                  <img
                    src="../assets/images/Free-Trial.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="col-md-6 d-flex flex-column align-items-center justify-content-center mt-3">
                  <h5>
                  Begin your project promptly with our widely acclaimed No-Obligation, No-Payment up to a 1-Week Free Trial. If content, seamlessly proceed with the same resource.

                  </h5>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="row card_main  mt-4">
                <div className="col-md-6 mt-3">
                  <img
                    src="../assets/images/Free-Recruitment.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="col-md-6 d-flex flex-column align-items-center justify-content-center mt-3">
                  <h5>
                  Leverage VM's complimentary, personalized recruitment support to transition from searching to hiring in a matter of days, if not hours. Eliminate extended waiting times and high local recruitment expenses for a single hire.

                  </h5>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="row card_main  mt-4">
                <div className="col-md-6 mt-3">
                  <img
                    src="../assets/images/Zero-Overheads.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="col-md-6 d-flex flex-column align-items-center justify-content-center mt-3">
                  <h5>
                  Get your own remote workplace in Pakistan, dodge nagging issues such as employee benefits, etc. and only pay your Blockchain expert’s salary.

                  </h5>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="row card_main  mt-4">
                <div className="col-md-6 mt-3">
                  <img
                    src="../assets/images/Data-Security.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="col-md-6 d-flex flex-column align-items-center justify-content-center mt-3">
                  <h5>
                  Being an ISO 27001:2013 certified and CMMiL3 assessed company, VM guarantees clients unparalleled data security and unwavering confidentiality at all times.
                  </h5>
                </div>
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default BlockTabs;
