/** @format */

import React from "react";

const Social = () => {
  const [showMore, setShowMore] = React.useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };
  return (
    <>
      <div className='social_sec'>
        <div className='bgImg'>
          <h4>
            Unleash Your Unique Brand Narrative Across Online Channels with Our
          </h4>
          <h2> Comprehensive Social Media Services </h2>
        </div>
        <div className='container'>
          <div className='row mt-5'>
            <div className='col-md-4 px-3 mt-3'>
              <div className='skill_img d-flex justify-content-center align-items-center'>
                <img src='../assets/images/facebook.svg' alt='' />
              </div>
              <h3>Facebook Marketing</h3>
              <h5>
                Utilize the power of Facebook to connect with your ideal
                audience. Our Social Media Marketing (SMM) specialists
                strategically post and promote videos, images, product
                collections, slideshows, offers, and event promotions, targeting
                specific micro-audiences to enhance visibility and engagement
                for your business and brand
              </h5>
            </div>
            <div className='col-md-4 px-3 mt-3'>
              <div className='skill_img d-flex justify-content-center align-items-center'>
                <img src='../assets/images/instagram.svg' alt='' />
              </div>
              <h3>Instagram Marketing </h3>
              <h5>
                Maximize the visual appeal of Instagram and captivate
                millennials through a blend of pictures, videos, carousels, and
                story ads. Our aim is to heighten awareness of your products and
                services, ensuring a strong connection with your target
                audience.
              </h5>
            </div>
            <div className='col-md-4 px-3 mt-3'>
              <div className='skill_img d-flex justify-content-center align-items-center'>
                <img src='../assets/images/linkedin.svg' alt='' />
              </div>
              <h3>LinkedIn Marketing </h3>
              <h5>
                Unlock tailored ad formats and bidding strategies on LinkedIn.
                Bring on board Social Media Marketing (SMM) experts to enhance
                your brand presence using video, image, text ads, sponsored
                content ads, follow company ads, sponsored InMail ads, and
                strategically target the B2B audience for effective lead
                generation and sales.
              </h5>
            </div>
            <div className='col-md-4 px-3 mt-3'>
              <div className='skill_img d-flex justify-content-center align-items-center'>
                <img src='../assets/images/youTube.svg' alt='' />
              </div>
              <h3>YouTube Marketing </h3>
              <h5>
                Showcase engaging and shareable content that resonates with your
                audience. Enlist the expertise of a social media advertising
                specialist to optimize display, overlay, skippable &
                non-skippable videos, sponsored card ads, and implement
                remarketing strategies, all designed to enhance awareness of
                your products or services.
              </h5>
            </div>
            <div className='col-md-4 px-3 mt-3'>
              <div className='skill_img d-flex justify-content-center align-items-center'>
                <img src='../assets/images/pinterest.svg' alt='' />
              </div>
              <h3>Pinterest Marketing </h3>
              <h5>
                Pinterest isn't just a source of inspiration; it's also a
                powerful marketing channel. Our Social Media Marketing (SMM)
                experts specialize in promoting your blog posts on Pinterest,
                driving direct traffic to your website and maximizing the
                platform's marketing potential.
              </h5>
            </div>
            <div className='col-md-4 px-3 mt-3'>
              <div className='skill_img d-flex justify-content-center align-items-center'>
                <img src='../assets/images/twitter.svg' alt='' />
              </div>
              <h3>Twitter Marketing</h3>
              <h5>
                Expand your follower base to boost brand awareness and loyalty.
                Enlist the expertise of Social Media Marketing (SMM)
                professionals to promote tweets, accounts, and trends. Increase
                followers, enhance awareness, drive video views, boost web
                traffic, encourage app installations, and generate more leads
                for your business
              </h5>
            </div>
          </div>
          {showMore ? (
            <div className='row mt-5'>
              <div className='col-md-4 px-3 mt-3'>
                <div className='skill_img d-flex justify-content-center align-items-center'>
                  <img
                    src='../assets/images/social-media-branding.svg'
                    alt=''
                  />
                </div>
                <h3>Social Media Targeting </h3>
                <h5>
                  Tailor your social media content to precisely target your
                  potential audience based on demographics, age, professional
                  background, and other relevant metrics.
                </h5>
              </div>
              <div className='col-md-4 px-3 mt-3'>
                <div className='skill_img d-flex justify-content-center align-items-center'>
                  <img src='../assets/images/filter-funnel.svg' alt='' />
                </div>
                <h3>Lead generation </h3>
                <h5>
                  Engage social media marketing experts to strategize
                  effectively and generate leads across various social networks.
                  This approach not only increases sales but also enhances your
                  brand's online exposure
                </h5>
              </div>
              <div className='col-md-4 px-3 mt-3'>
                <div className='skill_img d-flex justify-content-center align-items-center'>
                  <img src='../assets/images/marketing-market.svg' alt='' />
                </div>
                <h3>Influencers Marketing </h3>
                <h5>
                  Bring on board VM's certified social media marketing experts
                  to conduct research, identify, reach out to, and engage with
                  influencers capable of creating a significant impact on your
                  brand, services, and products' audience.
                </h5>
              </div>
              <div className='col-md-4 px-3 mt-3'>
                <div className='skill_img d-flex justify-content-center align-items-center'>
                  <img src='../assets/images/brand.svg' alt='' />
                </div>
                <h3>Brand Activation </h3>
                <h5>
                  Avoid bombarding your audience with generic social posts and
                  offers – it's not effective. With VE, leverage the expertise
                  of social media marketing professionals to actively engage and
                  communicate with your audience, fostering a connection that
                  turns them into enthusiastic brand supporters.
                </h5>
              </div>
              <div className='col-md-4 px-3 mt-3'>
                <div className='skill_img d-flex justify-content-center align-items-center'>
                  <img
                    src='../assets/images/seo-strategy-planning.svg'
                    alt=''
                  />
                </div>
                <h3>Campaign Planning </h3>
                <h5>
                  From market analysis and audience traits to funnel stages, we
                  delve deep into data before crafting pertinent social media
                  content. Every element undergoes meticulous split testing to
                  ensure a measurable return on investment.
                </h5>
              </div>
              <div className='col-md-4 px-3 mt-3'>
                <div className='skill_img d-flex justify-content-center align-items-center'>
                  <img
                    src='../assets/images/distribute-distribution.svg'
                    alt=''
                  />
                </div>
                <h3>Distribution & Management </h3>
                <h5>
                  We pinpoint the platforms frequented by your ideal customers
                  using robust analytics and retargeting campaigns, ensuring not
                  just identification but also conversion of potential customers
                  into paying ones for your brand.
                </h5>
              </div>

              <div className='col-md-4 px-3 mt-3'>
                <div className='skill_img d-flex justify-content-center align-items-center'>
                  <img src='../assets/images/gamification.svg' alt='' />
                </div>
                <h3>Gamification</h3>
                <h5>
                  Incorporate game mechanics into non-game environments, whether
                  it's a webpage, an online community, or a learning and
                  development application. This strategy aims to engage
                  consumers and inspire deeper collaboration with your brand.
                </h5>
              </div>

              <div className='col-md-4 px-3 mt-3'>
                <div className='skill_img d-flex justify-content-center align-items-center'>
                  <img src='../assets/images/promotion-marketing.svg' alt='' />
                </div>
                <h3>Remarketing </h3>
                <h5>
                  Utilize remarketing to reconnect with individuals who have
                  previously visited your website. Win back your lost audience
                  with tailored content, employing data-driven strategies, heat
                  maps, and insights into consumer behavior.
                </h5>
              </div>

              <div className='col-md-4 px-3 mt-3'>
                <div className='skill_img d-flex justify-content-center align-items-center'>
                  <img src='../assets/images/community-share.svg' alt='' />
                </div>
                <h3>Community Development </h3>
                <h5>
                  Harness the potential of social media to establish an online
                  community for meaningful engagement with potential customers.
                  Cultivate lasting and meaningful relationships through
                  interaction and connection-building.
                </h5>
              </div>
            </div>
          ) : null}
          {showMore ? (
            <div className='btn_main mt-5 d-flex justify-content-center align-items-center py-5'>
              <button onClick={toggleShowMore}>Read Less </button>
            </div>
          ) : (
            <div className='btn_main mt-5 d-flex justify-content-center align-items-center py-5'>
              <button onClick={toggleShowMore}>Read more </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Social;
