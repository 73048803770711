/** @format */

import React from "react";
import Hero from "../components/Elements/Virtual-Assistant/Hero";
import Work from "../components/Elements/Virtual-Assistant/Work";
import Virtual from "../components/Elements/Virtual-Assistant/Virtual";
import Lifting from "../components/Elements/Virtual-Assistant/Lifting";

const VirtualAssistant = () => {
  React.useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    scrollToTop();
  }, []);
  return (
    <>
      <Hero />
      <Work />
      <Virtual />
      <Lifting />
    </>
  );
};

export default VirtualAssistant;
