import React from 'react'
import Hero from '../components/Elements/Finance-Account/Hero'
import Finance from '../components/Elements/Finance-Account/Finance'
import Services from '../components/Elements/Finance-Account/Services'
import Benifits from '../components/Elements/Finance-Account/Benifits'
import Choose from '../components/Elements/Finance-Account/Choose'
import Skills from '../components/Elements/Finance-Account/Skills'

const FinanceAccount = () => {
  React.useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    scrollToTop();
  }, []);
  return (
    <>
        <Hero/>
        <Finance/>
        <Services/>
        <Benifits/>
        <Choose/>
        <Skills/>
    </>
  )
}

export default FinanceAccount