/** @format */

import React from "react";

const Services = () => {
  return (
    <>
      <div className='expert_sec'>
        <div className='bgImg'>
          <div className='text_bg'>
            <h5>360° Mobile App Development Services</h5>
            <h2> More Work. Less Noise.</h2>
          </div>
        </div>
        <div className='container'>
          <div className='row mt-5'>
            <div className='col-md-4 mt-3'>
              <div className='card_img'>
                <img src='../assets/images/apple.svg' alt='' />
              </div>
              <h4>iOS App Development</h4>
              <p style={{ textAlign: "center" }}>
                Get dedicated mobile app developers to leverage Swift and
                Objective-C for intuitive iOS apps (iPhone and iPad).
              </p>
            </div>
            <div className='col-md-4 mt-3'>
              <div className='card_img'>
                <img src='../assets/images/android.svg' alt='' />
              </div>
              <h4>Android App Development</h4>
              <p style={{ textAlign: "center" }}>
                Virtual Mate is one of the few mobile app development companies
                to cater to Kotlin, Android TV, Android phones, etc.
              </p>
            </div>
            <div className='col-md-4 mt-3'>
              <div className='card_img'>
                <img src='../assets/images/window.svg' alt='' />
              </div>
              <h4>Windows App Development</h4>
              <p style={{ textAlign: "center" }}>
                Looking forward to getting some UWPs (Universal Windows Platform
                apps) built? Look no further. Virtual Mate fits the bill.
              </p>
            </div>
            <div className='col-md-4 mt-3'>
              <div className='card_img'>
                <img src='../assets/images/mobile.svg' alt='' />
              </div>
              <h4>Hybrid App Development</h4>
              <p style={{ textAlign: "center" }}>
                Hire dedicated mobile app developers for hybrid apps built using
                HTML5 and JavaScript, wrapped in a native container.
              </p>
            </div>
            <div className='col-md-4 mt-3'>
              <div className='card_img'>
                <img src='../assets/images/app.svg' alt='' />
              </div>
              <h4>Native App Development</h4>
              <p style={{ textAlign: "center" }}>
                Being well-versed with Xcode, Objective-C, Eclipse, Java, XAML,
                and C#, our mobile app developers can build apps in no time.
              </p>
            </div>
            <div className='col-md-4 mt-3'>
              <div className='card_img'>
                <img src='../assets/images/smart.svg' alt='' />
              </div>
              <h4>Wearable App Development</h4>
              <p style={{ textAlign: "center" }}>
                From wearable app development to integration, hire a dedicated
                mobile app developer who knows it all.
              </p>
            </div>

            <div className='col-md-4 mt-3'>
              <div className='card_img'>
                <img src='../assets/images/app-desig.svg' alt='' />
              </div>
              <h4>Custom App Development</h4>
              <p style={{ textAlign: "center" }}>
                Virtual Mate headhunts the best Pakistani mobile app developers
                to ensure all your specific requirements are duly met.
              </p>
            </div>
            <div className='col-md-4 mt-3'>
              <div className='card_img'>
                <img src='../assets/images/mobile-development.svg' alt='' />
              </div>
              <h4>App Maintenance & Support</h4>
              <p style={{ textAlign: "center" }}>Our mobile app developers for hire not only build apps from scratch but also keep your apps up and running.</p>
            </div>
            <div className='col-md-4 mt-3'>
              <div className='card_img'>
                <img src='../assets/images/change-migrate.svg' alt='' />
              </div>
              <h4>Migration & App Porting</h4>
              <p style={{ textAlign: "center" }}>Would you like to migrate an existing app to a different platform? Let’s get the best mobile app developers to work on it.</p>
            </div>
            <div className='col-md-4 mt-3'>
              <div className='card_img'>
                <img src='../assets/images/xamarin.svg' alt='' />
              </div>
              <h4>Xamarin App Development</h4>
              <p style={{ textAlign: "center" }}>Hire developers to build apps that work like a charm on both iOS and Android devices without writing the code twice.</p>
            </div>
            <div className='col-md-4 mt-3'>
              <div className='card_img'>
                <img src='../assets/images/game-machine.svg' alt='' />
              </div>
              <h4>Mobile Game Development</h4>
              <p style={{ textAlign: "center" }}>Hire Virtual Mate developers to build mobile games that work flawlessly and keep users engaged with their feature-rich functionality.</p>
            </div>
            <div className='col-md-4 mt-3'>
              <div className='card_img'>
                <img src='../assets/images/augmented.svg' alt='' />
              </div>
              <h4>Augmented Reality Development</h4>
              <p style={{ textAlign: "center" }}>Augmented reality is the future (and the present). Tap into the massive industry with state-of-the-art apps built by Virtual Mate developers.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
