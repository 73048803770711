/** @format */

import React from "react";

const data = [
  {
    id: 1,
    image: "../assets/images/k1.svg",
    head: "Data Entry",
    para: "Virtual Mate’s data entry professionals have expertise in online and offline data entry, PDF to Excel conversions, image data entry, legal data entry, document data entry, product data entry for e-commerce platforms, etc. No matter the task, your data entry expert is up to it.",
  },
  {
    id: 2,
    image: "../assets/images/k2.svg",
    head: "Data Processing",
    para: "Virtual Mate’s remote data processing experts perform insurance claim processing, order entry processing, purchase order management, transaction data processing, records indexing, mailing list compilation, word processing, and formatting among other related tasks flawlessly.",
  },
  {
    id: 3,
    image: "../assets/images/k3.svg",
    head: "Data Conversion",
    para: "Virtual Mate’s offshore data entry experts specialize in XML, HTML, PDF, Excel, image to doc, forms processing, catalog conversion, form processing, SGML conversion, e-book conversion, and document conversion. These are among some of the conversion tasks that Virtual Mate’s experts perform.",
  },
  {
    id: 4,
    image: "../assets/images/k4.svg",
    head: "Data Cleansing",
    para: "Virtual Mate’s data entry experts also have expertise in data cleaning and enrichment to provide the clients exactly what they are looking for. Our experts have provided data cleansing services to over 900 clients over the last 10 years.",
  },
  {
    id: 5,
    image: "../assets/images/k5.svg",
    head: "Database Management",
    para: "Database management is essential for the effective functioning of a modern company. Without ready access to data as and when needed, entire processes can come to a halt. Hire Virtual Mate’s online data entry experts for DB development and migration, and CRM database management.",
  },
  {
    id: 6,
    image: "../assets/images/k6.svg",
    head: "Data Mining",
    para: "It is imperative for any data-based firm to be effective at mining the relevant information from reams of data. Virtual Mate’s offshore data entry experts excel at data mining and database and address validation to ensure that you get exactly the data you need when you need it.",
  },
  {
    id: 7,
    image: "../assets/images/k7.svg",
    head: "Web Research",
    para: "Virtual Mate’s online data entry experts are adept at performing web research for any project or task you are engaged in. They perform data research, market and legal research, product and services research, LinkedIn data research, and email, name and address research as well.",
  },
  {
    id: 8,
    image: "../assets/images/k8.svg",
    head: "Forms Processing",
    para: "Virtual Mate’s triple-vetted data entry experts excel at forms processing, including market research forms, product registration forms, credit card forms, legal procedure research, mortgage forms, and survey forms among other such pertinent tasks that your firm may need.",
  },
  {
    id: 9,
    image: "../assets/images/k9.svg",
    head: "Scanning and OCR",
    para: "Data extraction, OCR clean up, data capture, document management systems, and document indexing services are some of the scanning and OCR tasks that Virtual Mate’s dedicated data entry experts perform on a daily basis for a whole host of our clients spread across 33 countries.",
  },
];

const Services = () => {
  return (
    <>
      <div className='service_data_sec'>
        <div className='container'>
          <h2>360° Data Entry Services </h2>
          <div className='d-flex justify-content-center'>
            <h5>Myriad data possibilities. Custom solutions</h5>
          </div>
          <div className='row mt-5'>
            {data.map(res => {
              return (
                <div className='col-md-4 mt-3 px-4'>
                  <div className='card_img'>
                    <img src={res.image} className='img-fluid' alt='' />
                  </div>
                  <h4>{res.head}</h4>
                  <p>{res.para}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
