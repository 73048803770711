/** @format */

import React from "react";
import ContactForms from "../../Utils/ContactForms";

const Hero = () => {
  return (
    <>
      <div className='frontend_hero'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-7 d-flex flex-column justify-content-center '>
              <h2>Hire Game Developers</h2>
              <p>Starts from US $14/Hour — Save 70%</p>
              <h5>
                Discover Our Game Development Expertise <br />
                With Our Strong & Proven Track Record
              </h5>
              <ul className='front'>
                <li>50+ Unity 3D Developers </li>
                <li>1100+ Successful Projects </li>
                <li>98.4% Client Retention Rate </li>
                <li>7+ Years’ Industry Experience</li>
              </ul>
            </div>
            <div className='col-md-5'>
              <ContactForms />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
