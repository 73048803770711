import './App.scss';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import Base from './components/Layouts/Base';
import About from './pages/About';
import Frontend from './pages/Frontend';
import Marketing from './pages/Marketing';
import ContentWriting from './pages/ContentWriting';
import GraphicDesigner from './pages/GraphicDesigner';
import VirtualAssistant from './pages/VirtualAssistant';
import DataEntry from './pages/DataEntry';
import Multimedia from './pages/Multimedia';
import MobileDev from './pages/MobileDev';
import DigitalMarketing from './pages/DigitalMarketing';
import FinanceAccount from './pages/FinanceAccount';
import SmartContract from './pages/SmartContract';
import TelegramTrading from './pages/TelegramTrading';
import GameStudio from './pages/GameStudio';
import BlockChain from './pages/BlockChain';
import GetStarted from './pages/GetStarted';


function App() {
  return (
    <div className="App">
       <BrowserRouter>
      <Base>
        <Routes>
          <Route index path="/" element={<Home/>} />
          <Route  path="/about" element={<About/>} />
          <Route  path="/get-started" element={<GetStarted/>} />
          <Route  path="/services/it-outsourcing" element={<Frontend/>} />
          <Route  path="/services/social-media-marketing" element={<Marketing/>} />
          <Route  path="/services/content-writing" element={<ContentWriting/>} />
          <Route  path="/services/graphic-designer" element={<GraphicDesigner/>} />
          <Route  path="/services/virtual-assistant" element={<VirtualAssistant/>} />
          <Route  path="/services/data-entry" element={<DataEntry/>} />
          <Route  path="/services/multimedia-animation" element={<Multimedia/>} />
          <Route  path="/services/mobile-app-dev" element={<MobileDev/>} />
          <Route  path="/services/digital-marketing" element={<DigitalMarketing/>} />
          <Route  path="/services/finance-account" element={<FinanceAccount/>} />
          <Route  path="/services/smart-contract" element={<SmartContract/>} />
          <Route  path="/services/telegram-trading" element={<TelegramTrading/>} />
          <Route  path="/services/game-studio" element={<GameStudio/>} />
          <Route  path="/services/blockchain" element={<BlockChain/>} />
          {/* <Route path="/*" element={<PageNotFound />}/> */}
        </Routes>
      </Base>
    </BrowserRouter>
    </div>
  );
}

export default App;
