/** @format */

import React from "react";

const Skills = () => {
  return (
    <>
      <div className='skill_sec'>
        <div className='container'>
          <h2>Enhance Your Business Evolution with Virtual Mate’s</h2>
          <p>Complete 360° IT Outsourcing Services</p>
          <div className='row mt-5'>
            <div className='col-md-4 px-3 mt-3'>
              <div className='skill_img d-flex justify-content-center align-items-center'>
                <img src='../assets/images/consulting.png' alt='' />
              </div>
              <h3> Navigating Technology Challenges </h3>
              <h5>
                Tackle crucial enterprise hurdles effortlessly. Virtual Mate's
                adept technology consultants can assist organizations in
                brainstorming, designing, deploying, testing, and maintaining
                applications and websites, leveraging their cross-sector
                expertise.
              </h5>
            </div>
            <div className='col-md-4 px-3 mt-3'>
              <div className='skill_img d-flex justify-content-center align-items-center'>
                <img src='../assets/images/crm.svg' alt='' />
              </div>
              <h3> Crafting Tailored CMS Solutions</h3>
              <h5>
                Virtual Mate's CMS developers excel in WordPress, Drupal,
                Joomla, Magento, DotNetNuke, Typo3, ExpressionEngine, and more.
                We specialize in developing custom content management systems to
                meet the unique needs of your business.
              </h5>
            </div>
            <div className='col-md-4 px-3 mt-3'>
              <div className='skill_img d-flex justify-content-center align-items-center'>
                <img src='../assets/images/cloud-hosting-icon.png' alt='' />
              </div>
              <h3>Complete Web Development & Hosting Solutions </h3>
              <h5>
                Whether it's creating websites, blogs, or providing hosting
                services, your certified web developer at Virtual Mate is
                proficient in various front-end and back-end technologies,
                including PHP, .NET, Java, HTML, CSS, and more.
              </h5>
            </div>
            <div className='col-md-4 px-3 mt-3'>
              <div className='skill_img d-flex justify-content-center align-items-center'>
                <img src='../assets/images/code-development.png' alt='' />
              </div>
              <h3>Tailored Software Solutions </h3>
              <h5>
                Acquire customized software from Virtual Mate's dedicated
                developers, well-versed in diverse software development
                languages like Python, Java, VB.NET, Flex, and adept in custom
                programming.
              </h5>
            </div>
            <div className='col-md-4 px-3 mt-3'>
              <div className='skill_img d-flex justify-content-center align-items-center'>
                <img src='../assets/images/mobile-app.png' alt='' />
              </div>
              <h3>Comprehensive Web and Mobile App Solutions </h3>
              <h5>
                Entrust the development, design, and testing of your web and
                mobile apps to Virtual Mate's IT experts. From conceptualization
                to ongoing support, Virtual Mate's mobile app developers can
                significantly reduce your time to market, ensuring a seamless
                and efficient process.
              </h5>
            </div>
            <div className='col-md-4 px-3 mt-3'>
              <div className='skill_img d-flex justify-content-center align-items-center'>
                <img src='../assets/images/frontend.png' alt='' />
              </div>
              <h3>Frontend Development </h3>
              <h5>
                Proficient in HTML, CSS, and JS, and with expertise in UI/UX
                design and development, Virtual Mate’s frontend developers
                skillfully create responsive and user-friendly interfaces that
                enhance the navigation experience for your visitors.
              </h5>
            </div>
            <div className='col-md-4 px-3 mt-3'>
              <div className='skill_img d-flex justify-content-center align-items-center'>
                <img src='../assets/images/web-development.png' alt='' />
              </div>
              <h3> Backend Solutions</h3>
              <h5>
                Engage dedicated backend developers to construct a customized
                backend solution for your enterprise. Watch your concept
                seamlessly transition from idea to implementation with precision
                and speed.
              </h5>
            </div>
            <div className='col-md-4 px-3 mt-3'>
              <div className='skill_img d-flex justify-content-center align-items-center'>
                <img src='../assets/images/devops-icon.png' alt='' />
              </div>
              <h3> DevOps Development Excellence </h3>
              <h5>
                Virtual Mate’s skilled DevOps developers in Pakistam can
                efficiently expedite your product's time to market using
                innovative concepts such as virtualization, server security,
                containerization, and more.
              </h5>
            </div>
            <div className='col-md-4 px-3 mt-3'>
              <div className='skill_img d-flex justify-content-center align-items-center'>
                <img src='../assets/images/ai.png' alt='' />
              </div>
              <h3> Power of Artificial Intelligence </h3>
              <h5>
                Leverage Virtual Mate's seasoned AI developers to craft an
                effective data strategy that acts as a catalyst for your overall
                business growth. Optimize, enhance, and refine your strategy
                with the expertise of our AI professionals.
              </h5>
            </div>
            <div className='col-md-4 px-3 mt-3'>
              <div className='skill_img d-flex justify-content-center align-items-center'>
                <img src='../assets/images/ecommerce-portal.png' alt='' />
              </div>
              <h3>Comprehensive eCommerce Solutions </h3>
              <h5>
                Whether it's establishing online stores on platforms like
                Magento, Shopify, WooCommerce, or creating custom eCommerce
                solutions with seamless integrations, find it all at Virtual
                Mate.
              </h5>
            </div>
            <div className='col-md-4 px-3 mt-3'>
              <div className='skill_img d-flex justify-content-center align-items-center'>
                <img src='../assets/images/device-embedded.svg' alt='' />
              </div>
              <h3>Complete Embedded Development Solutions</h3>
              <h5>
                Virtual Mate is your all-in-one destination for embedded
                development solutions, covering everything from IoT to PCB and
                firmware design services, mechanical design services, and
                embedded system/application software.
              </h5>
            </div>
            <div className='col-md-4 px-3 mt-3'>
              <div className='skill_img d-flex justify-content-center align-items-center'>
                <img src='../assets/images/data-science-icon.png' alt='' />
              </div>
              <h3>Data Science Excellence </h3>
              <h5>
                Enlist data science experts to dissect intricate data into a
                coherent whole. They'll assist you in uncovering connections,
                identifying trends, and making critical deductions to guide your
                next plan of action.
              </h5>
            </div>
            <div className='col-md-4 px-3 mt-3'>
              <div className='skill_img d-flex justify-content-center align-items-center'>
                <img src='../assets/images/database.svg' alt='' />
              </div>
              <h3>Seamless Database Management</h3>
              <h5>
                Effortlessly craft error-free database management systems,
                including Multidimensional and Relational DBMS, Network
                databases, and Object-oriented databases, with utmost ease.
              </h5>
            </div>
            <div className='col-md-4 px-3 mt-3'>
              <div className='skill_img d-flex justify-content-center align-items-center'>
                <img src='../assets/images/Website-Testing.png' alt='' />
              </div>
              <h3>Reliable Software Testing Solutions </h3>
              <h5>
                Guarantee bug-free software through a combination of automated
                software testing, manual testing, white-box testing, and
                black-box testing. Swiftly deploy flawless software to your
                market.
              </h5>
            </div>
            <div className='col-md-4 px-3 mt-3'>
              <div className='skill_img d-flex justify-content-center align-items-center'>
                <img src='../assets/images/cloud-remote.png' alt='' />
              </div>
              <h3>Cloud Consulting Excellence </h3>
              <h5>
                Engage remote professionals from Pakistan to leverage the
                benefits of employing suitable platforms and services, such as
                PaaS, IaaS, and BaaS, to elevate your business revenue.
              </h5>
            </div>
            <div className='col-md-4 px-3 mt-3'>
              <div className='skill_img d-flex justify-content-center align-items-center'>
                <img src='../assets/images/blockchain.svg' alt='' />
              </div>
              <h3>Blockchain Advisory Expertise </h3>
              <h5>
                Delegate blockchain consulting services to assess the potential
                of blockchain technology for your business case through
                real-world applications.
              </h5>
            </div>
            <div className='col-md-4 px-3 mt-3'>
              <div className='skill_img d-flex justify-content-center align-items-center'>
                <img src='../assets/images/solution.png' alt='' />
              </div>
              <h3>Comprehensive IT Support Solutions </h3>
              <h5>
                Access 4-level IT support for Windows, Linux, VMware, and HyperV
                across 8 major IT areas – Software, Hardware, Network, Security,
                Voice, Cloud, Virtualization, Email, and Office 365 support.
              </h5>
            </div>
            <div className='col-md-4 px-3 mt-3'>
              <div className='skill_img d-flex justify-content-center align-items-center'>
                <img src='../assets/images/machine-learing.png' alt='' />
              </div>
              <h3>Tailored Machine Learning Solutions </h3>
              <h5>
                Bring in ML experts to receive custom solutions to IT-related
                challenges. Their expertise extends to creating data-to-training
                models, automating processes like speech recognition, market
                forecasting, and more.
              </h5>
            </div>
            <div className='col-md-4 px-3 mt-3'>
              <div className='skill_img d-flex justify-content-center align-items-center'>
                <img src='../assets/images/ms-office-icon.png' alt='' />
              </div>
              <h3>Microsoft Office 365 Excellence </h3>
              <h5>
                Opt for our professional Microsoft Office 365 services to
                minimize risk, downtime, and support costs. Let your dedicated
                team focus on core business operations while we handle the
                intricacies.
              </h5>
            </div>
            <div className='col-md-4 px-3 mt-3'>
              <div className='skill_img d-flex justify-content-center align-items-center'>
                <img src='../assets/images/neural-network-icon.png' alt='' />
              </div>
              <h3>Efficient Network Installation </h3>
              <h5>
                Virtual Mate’s network administrators guarantee the seamless
                operation of your network and workloads, including tasks such as
                firewall configuration, user access restrictions, country-wise
                access restrictions, and more.
              </h5>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Skills;
