/** @format */

import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const data = [
  {
    id: 1,
    image: "assets/images/hero1.jpeg",
    name: "Keenan Jaenicke",
    title: "Node.js, React",
    para: "Previously at Salesforce",
  },
  {
    id: 2,
    image: "assets/images/hero2.jpeg",
    name: "Sasha Reid",
    title: "Python, AWS",
    para: "Previously at Nava PBC",
  },
  {
    id: 3,
    image: "assets/images/hero3.jpg",
    name: "Christian Quinto",
    title: "React, GraphQL",
    para: "Previously at Birchbox",
  },
  {
    id: 4,
    image: "assets/images/hero4.jpg",
    name: "Kyle Rhodes",
    title: "Vue.js, Ruby on Rails",
    para: "Previously at Big Nerd Ranch",
  },
  {
    id: 5,
    image: "assets/images/hero5.jpg",
    name: "Angela Panfil",
    title: "React, PHP",
    para: "Previously at Refinery29",
  },
  {
    id: 6,
    image: "assets/images/hero6.jpg",
    name: "Brian de la Motte",
    title: "Python, SQL",
    para: "Previously at Netlify",
  },
];

const Hero2 = () => {
  return (
    <>
      <div className='hero2_sec'>
        <div className='container'>
          <img
            src='assets/images/logo.png'
            className='d-block m-auto mb-3 '
            width={200}
            alt=''
          />
          <h2>
            Hire top-rated tech talent in <span>days</span>
          </h2>
          <p>
            Get access to developer-vetted talent with a proven track record of
            performance
          </p>
          <div className='btn_main mt-5'>
            <Link to='get-started'>
              {" "}
              <button>Get started now</button>
            </Link>
          </div>
          <Carousel
            responsive={responsive}
            arrows={false}
            autoPlay={true}
            autoPlaySpeed={3000}
            draggable
            showArrows={true}
            focusOnSelect={false}
            infinite={true}
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            showDots={false}>
            {data.map(res => {
              return (
                <div className='card'>
                  <div className='d-flex align-items-center gap-2'>
                    <div className='card_img'>
                      <img src={res.image} className='img-fluid' alt='' />
                    </div>
                    <div className='card_txt'>
                      <h3>{res.name}</h3>
                      <h4>{res.title}</h4>
                      <h5>{res.para}</h5>
                    </div>
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    </>
  );
};

export default Hero2;
