import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

const Virtual = () => {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };
  return (
    <>
      <div className="services_sec">
        <div className="container">
          <div className="d-flex justify-content-center">
            <div className="border_top"></div>
          </div>
          <h2>There’s a Mountain of Work ahead of you </h2>
          <p className="py-3">
          Ease Your Load and Hire Virtual Assistants.
          </p>
          <div className="row  my-4">
            <div className="col-md-3 services mt-5 ">
              <div className="d-flex justify-content-center">
                <img
                  src="../assets/images/v1.svg"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <h4>Personal Assistant</h4>
            </div>
            <div className="col-md-3 services mt-5">
              <div className="d-flex justify-content-center">
                <img
                  src="../assets/images/v2.svg"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <h4>Administrative Assistant</h4>
            </div>
            <div className="col-md-3 services mt-5">
              <div className="d-flex justify-content-center">
                <img
                  src="../assets/images/v3.svg"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <h4>Data Entry</h4>
            </div>
            <div className="col-md-3 services mt-5">
              <div className="d-flex justify-content-center">
                <img
                  src="../assets/images/v4.svg"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <h4>Bookkeeping & Accounting</h4>
            </div>
            <div className="col-md-3 services mt-5">
              <div className="d-flex justify-content-center">
                <img
                  src="../assets/images/v5.svg"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <h4>Customer Support</h4>
            </div>
            <div className="col-md-3 services mt-5">
              <div className="d-flex justify-content-center">
                <img
                  src="../assets/images/v6.svg"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <h4>Digital Marketing</h4>
            </div>
            <div className="col-md-3 services mt-5">
              <div className="d-flex justify-content-center">
                <img
                  src="../assets/images/v7.svg"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <h4>Content Writing</h4>
            </div>
            <div className="col-md-3 services mt-5">
              <div className="d-flex justify-content-center">
                <img
                  src="../assets/images/v8.svg"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <h4>Travel Research</h4>
            </div>

            {showMore ? (
              <>
                <div className="col-md-3 services mt-4">
                  <div className="d-flex justify-content-center">
                    <img
                      src="../assets/images/v9.svg"
                      style={{ width: "34%" }}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <h4>Social Media Management</h4>
                </div>
                <div className="col-md-3 services mt-5">
                  <div className="d-flex justify-content-center">
                    <img
                      src="../assets/images/v10.svg"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <h4>Transcription</h4>
                </div>
                <div className="col-md-3 services mt-5">
                  <div className="d-flex justify-content-center">
                    <img
                      src="../assets/images/v11.svg"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <h4>Microsoft Excel</h4>
                </div>
                <div className="col-md-3 services mt-5">
                  <div className="d-flex justify-content-center">
                    <img
                      src="../assets/images/v12.svg"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <h4>Email Management</h4>
                </div>
              
              </>
            ) : null}
          </div>
          {showMore ? (
            <div className="btn_main d-flex justify-content-center align-items-center py-5">
              <button onClick={toggleShowMore}>View Less Services</button>
            </div>
          ) : (
            <div className="btn_main d-flex justify-content-center align-items-center py-5">
              <button onClick={toggleShowMore}>View All Services</button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Virtual;
