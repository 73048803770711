import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";


const Expert = () => {
  return (
    <>
        <div className="differ_sec">
        <div className="container">
          <h5>Get a Glimpse of VE’s Creative Minds</h5>
          <h2>Our Multimedia Animation Work Portfolio</h2>
          <Tabs>
            <TabList>
          <div className="d-flex justify-content-center align-items-center flex-wrap  mt-5">
            <Tab><button>2D Animation</button></Tab>
            <Tab><button>3D Animation</button></Tab>
            <Tab>  <button> E Learning</button></Tab>
            <Tab><button> Promotional Video</button></Tab>
            <Tab><button> Print Media</button></Tab>
            <Tab><button> Game Design</button></Tab>
            <Tab><button> Architectural Solutions</button></Tab>
          </div>
            </TabList>
            <TabPanel>
                <div className="row mt-4">
                    <div className="col-md-4">
                        <img src="../assets/images/t1.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-4">
                        <img src="../assets/images/t2.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-4">
                        <img src="../assets/images/t3.jpg" className="img-fluid" alt="" />
                    </div>
                </div>
            </TabPanel>
            <TabPanel>
            <div className="row mt-4">
                    <div className="col-md-4">
                        <img src="../assets/images/t4.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-4">
                        <img src="../assets/images/t5.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-4">
                        <img src="../assets/images/t6.jpg" className="img-fluid" alt="" />
                    </div>
                </div>
            </TabPanel>
            <TabPanel>
            <div className="row mt-4">
                    <div className="col-md-4">
                        <img src="../assets/images/t7.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-4">
                        <img src="../assets/images/t8.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-4">
                        <img src="../assets/images/t9.jpg" className="img-fluid" alt="" />
                    </div>
                </div>
            </TabPanel>
            <TabPanel>
            <div className="row mt-4">
                    <div className="col-md-4">
                        <img src="../assets/images/t10.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-4">
                        <img src="../assets/images/t11.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-4">
                        <img src="../assets/images/t12.jpg" className="img-fluid" alt="" />
                    </div>
                </div>
            </TabPanel>
            <TabPanel>
            <div className="row mt-4">
                    <div className="col-md-4">
                        <img src="../assets/images/t13.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-4">
                        <img src="../assets/images/t14.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-4">
                        <img src="../assets/images/t15.jpg" className="img-fluid" alt="" />
                    </div>
                </div>
            </TabPanel>
            <TabPanel>
                <div className="row mt-4">
                    <div className="col-md-4">
                        <img src="../assets/images/t1.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-4">
                        <img src="../assets/images/t2.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-4">
                        <img src="../assets/images/t3.jpg" className="img-fluid" alt="" />
                    </div>
                </div>
            </TabPanel>
            <TabPanel>
            <div className="row mt-4">
                    <div className="col-md-4">
                        <img src="../assets/images/t13.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-4">
                        <img src="../assets/images/t14.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-4">
                        <img src="../assets/images/t15.jpg" className="img-fluid" alt="" />
                    </div>
                </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </>
  )
}

export default Expert