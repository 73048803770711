import React from 'react'
import ContactForms from '../../Utils/ContactForms'

const Hero = () => {
  return (
    <>
            <div className="frontend_hero">
            <div className="container">
                <div className="row">
                    <div className="col-md-7 d-flex flex-column justify-content-center ">
                        <h2>Hire Smart Contract Developers    </h2>
                        <p>Starts from USD $20/Hour - Save 70%</p>
                        <h5> Create Highly Scalable, Powerful and non vulnerable smart contracts,</h5>
                        <ul className='front'>
                            <li>50+ in-house Smart contract developers  </li>
                            <li>Expertise in Solidity   </li>
                            <li>150+ smart contract projects successfully delivered </li>
                        </ul>
                    </div>
                    <div className="col-md-5">
                    <ContactForms />

                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Hero