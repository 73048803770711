/** @format */

import React from "react";
import Hero from "../components/Elements/About/Hero";
import Adventages from "../components/Elements/About/Adventages";
import Professional from "../components/Elements/About/Professional";
import Technology from "../components/Elements/About/Technology";
import Who from "../components/Elements/About/Who";

const About = () => {
  React.useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    scrollToTop();
  }, []);
  return (
    <>
      <Hero />
      <Adventages />
      <Professional />
      <Technology />
      <Who />
    </>
  );
};

export default About;
