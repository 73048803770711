/** @format */

import React from "react";

const Grow = () => {
  return (
    <>
      <div className='design_sec'>
        <div className='container'>
          <h5>How Outsourcing Animation Services</h5>
          <h2> Can Help Your Business Grow</h2>
          <p>
            Transform Your Conversions with Quality Animation Services that
            Elevate Creativity. Hire Animators from Pakistan to Set a Benchmark
            in Multimedia Animation Innovation. Benefit from Access to
            Experienced Professionals at a Fraction of the Cost Compared to Your
            Country. Outsourcing to Animation Companies in Pakistan Offers
            Cost-Effectiveness, Scalability of Resources, and Flexible Work
            Timings.
          </p>
        </div>
      </div>
    </>
  );
};

export default Grow;
