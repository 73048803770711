import React from "react";

const OutSource = () => {
  return (
    <>
      <div className="outsource_sec">
        <div className="container">
            <div className="row">
                <div className="col-md-3">
                  <ul className="outsource_left d-flex flex-column justify-content-around align-items-center">
                    <li>
                      <div className="d-flex  align-items-center">
                        <h5>US, UK Certifications in Persuasive Copywriting</h5>
                        <div className="icon_bg">
                            <img src="../assets/images/certificate.svg" className="img-fluid" alt="" />
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex  align-items-center">
                        <h5>Online Research Experts</h5>
                        <div className="icon_bg">
                            <img src="../assets/images/research.svg" className="img-fluid" alt="" />
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex  align-items-center">
                        <h5>University-Educated, Ex-Journalists</h5>
                        <div className="icon_bg">
                            <img src="../assets/images/writing.svg" className="img-fluid" alt="" />
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6 d-flex justify-content-center align-items-center">
                  <div className="spin_img position-relative">
                    <img src="../assets/images/spin.png" className="img-fluid" alt="" />
                    <div className="text_bg ">
                        <h3>Why Choose Virtual Mate for Outsourcing Content Writing Services?</h3>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                <ul className="outsource_left d-flex flex-column justify-content-around align-items-center">
                    <li>
                      <div className="d-flex  align-items-center">
                        <div className="icon_bg" style={{marginRight: '15px',marginLeft: '0px'}}>
                            <img src="../assets/images/open.svg" className="img-fluid" alt="" />
                        </div>
                        <h5 style={{textAlign: 'left'}}>100% Plagiarism-Free Content</h5>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex  align-items-center">
                        <div className="icon_bg" style={{marginRight: '15px',marginLeft: '0px'}}>
                            <img src="../assets/images/browser.svg" className="img-fluid" alt="" />
                        </div>
                        <h5 style={{textAlign: 'left'}}>Can Write for All Domains & Industries</h5>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex  align-items-center">
                        <div className="icon_bg" style={{marginRight: '15px',marginLeft: '0px'}}>
                            <img src="../assets/images/expert.svg" className="img-fluid" alt="" />
                        </div>
                        <h5 style={{textAlign: 'left'}}>Average 7+ Years’ Experience per Writer</h5>
                      </div>
                    </li>
                  </ul>
                </div>
            </div>
        </div>
      </div>
    </>
  );
};

export default OutSource;
