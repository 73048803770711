/** @format */

import React from "react";

const Expert = () => {
  return (
    <>
      <div className='expert_Sec'>
        <div className='container'>
          <h2> Virtual Mate’s Data Entry Experts also Excel at </h2>
          <div className='d-flex justify-content-center'>
            <h5>Tested technical wizards to keep you ahead </h5>
          </div>
          <div className='row card_main mt-5'>
            <div className='col-md-7'>
              <h4>E-commerce Data Entry Services</h4>
              <p>
                Hire dedicated e-commerce product data entry specialists with
                extensive experience of working for various shopping platforms.
                Your dedicated resource can build custom processes for your
                business so that your product data catalog can be managed
                efficiently.
              </p>
            </div>
            <div className='col-md-5'>
              <div className='card_img'>
                <img
                  src='../assets/images/ecom.jpg'
                  className='img-fluid'
                  alt=''
                />
              </div>
            </div>
          </div>
          <div className='row card_main mt-5'>
            <div className='col-md-5'>
              <div className='card_img'>
                <img
                  src='../assets/images/epub.jpg'
                  className='img-fluid'
                  alt=''
                />
              </div>
            </div>
            <div className='col-md-7'>
              <h4>ePUB Conversion Services</h4>
              <p>
                Our data entry professionals offer high-quality ePUB conversion
                and formatting services for PDF, MS Word, QuarkXPress, InDesign,
                XML, HTML, 3B2, RTF, etc. ePUB Conversion Services can expand
                market search, improve circulation, and maximize advertiser
                value by lowering overall costs.
              </p>
            </div>
          </div>
          <div className='row card_main mt-5'>
            <div className='col-md-7'>
              <h4>Indexing Services</h4>
              <p>
                Indexing services can boost the efficiency of your organization
                by organizing your information into a database in such a way
                that it can be easily searched. Virtual Mate has a team of
                highly efficient data entry experts with vast experience in
                indexing services.
              </p>
            </div>
            <div className='col-md-5'>
              <div className='card_img'>
                <img
                  src='../assets/images/index.jpg'
                  className='img-fluid'
                  alt=''
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Expert;
