/** @format */

import React from "react";
import Faq from "react-faq-component";
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import { BsFillQuestionOctagonFill } from "react-icons/bs";
import { Link } from "react-router-dom";

const Faqs = () => {
  const data = {
    //   title: "FAQ (How it works)",
    rows: [
      {
        title: (
          <div className='d-flex flex-wrap align-items-center gap-2 '>
            <BsFillQuestionOctagonFill />
            <p style={{ margin: "0%" }}>
              Can I see the office? Do you have pictures?
            </p>
          </div>
        ),
        content: (
          <div className='d-flex flex-wrap gap-1 align-items-center'>
            <p style={{ margin: "0%" }}>
              Sure, you can. Please visit our Image Gallery at
            </p>
            <Link> https://www.virtualmate.io/image-gallery</Link>
          </div>
        ),
      },
      {
        title: (
          <div className='d-flex flex-wrap align-items-center gap-2 '>
            <BsFillQuestionOctagonFill />
            <p style={{ margin: "0%" }}>How long have you been in business?</p>
          </div>
        ),
        content: (
          <div className='d-flex flex-wrap  gap-1 align-items-center'>
            <p style={{ margin: "0%" }}>
              We have been in business for over 15 years now. Our dedicated
              virtual employees have worked for over 3500+ clients from 48
              countries and across 5 continents.
            </p>
          </div>
        ),
      },

      {
        title: (
          <div className='d-flex flex-wrap align-items-center gap-2 '>
            <BsFillQuestionOctagonFill />
            <p style={{ margin: "0%" }}>
              Are you like oDesk? Do your employees work from their homes?
            </p>
          </div>
        ),
        content: (
          <div className='d-flex flex-wrap flex-wrap gap-1 align-items-center'>
            <p style={{ margin: "0%" }}>
              No, we are not like oDesk or the other freelancing websites. You
              hire dedicated employees from us who work exclusively for you from
              our professional, well-equipped, and supervised offices. Your
              virtual employee does NOT work from home.
            </p>
          </div>
        ),
      },

      {
        title: (
          <div className='d-flex flex-wrap align-items-center gap-2 '>
            <BsFillQuestionOctagonFill />
            <p style={{ margin: "0%" }}>What is VirtualMate.io all about?</p>
          </div>
        ),
        content: (
          <div className='d-flex flex-wrap gap-1 align-items-center'>
            <p style={{ margin: "0%" }}>
              VirtualMate.io is a leading remote staffing company,
              headquartered near New Delhi, India, with five branch offices in
              Pakistan, and one in the UK. Since 2007, it has been helping Small
              and Medium Businesses in 48 countries and across 5 continents to
              hire dedicated virtual employees from Pakistan in any domain or
              profession. The domains range from specialist skills like IT and
              ITES, Software Programming, Law, Accounts, Engineering, to
              non-technical skills like SEO, Content, Transcription, and much
              else. Every client gets to handpick their desired employee who
              works exclusively and dedicatedly for them from our well-equipped
              and supervised offices.
            </p>
          </div>
        ),
      },
    ],
  };

  const styles = {
    // bgColor: "#0E0E1DBF",
    titleTextColor: "#EBEEF2",
    rowTitleColor: "#2C3A4B",
    rowContentColor: "##394452",
    arrowColor: "##394452",
    rowContentPaddingTop: "10px",
    rowContentPaddingBottom: "10px",
    rowContentPaddingLeft: "30px",
    rowContentPaddingRight: "30px",
  };

  const config = {
    animate: true,
    // arrowIcon: "V",
    // tabFocus: true
    expandIcon: <FaPlusCircle color='#0089ff' />,
    collapseIcon: <FaMinusCircle color='#0089ff' />,
    margin: "2px",
  };

  return (
    <div className='container-fluid faqs'>
      <div className='container'>
        <div className='section-heading text-center'>
          <h2 className='accent-large'>Who We Are</h2>
        </div>

        <div className='mt-4'>
          <Faq data={data} styles={styles} config={config} />
        </div>
      </div>
    </div>
  );
};

export default Faqs;
