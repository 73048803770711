/** @format */

import React from "react";
import Hero from "../components/Elements/Hero";
import Resource from "../components/Elements/Resource";
import Services from "../components/Elements/Services";
import Sections from "../components/Elements/Sections";
import Hiring from "../components/Elements/Hiring";
import Global from "../components/Elements/Global";
import Requirement from "../components/Elements/Requirement";
import Future from "../components/Elements/Future";
import Questions from "../components/Elements/Questions";
import Work from "../components/Elements/Work";
import Cards from "../components/Elements/Cards";
import Hero2 from "../components/Elements/Hero2";

const Home = () => {
  React.useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    scrollToTop();
  }, []);
  return (
    <>
      {/* <Hero/> */}
      <Hero2 />
      <Resource />
      <Cards />
      <Services />
      <Sections />
      <Future />
      <Work />
      <Hiring />
      {/* <Global/> */}
      {/* <Requirement/> */}
      <Questions />
    </>
  );
};

export default Home;
