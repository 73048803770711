import React from "react";
import { BsGithub } from "react-icons/bs";
import { GrFacebookOption, GrLinkedinOption } from "react-icons/gr";
import { BsTwitter } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";
import { FaPinterestP } from "react-icons/fa";

const Footer = () => {
  return (
    <>
      <div className="stay_sec">
        <div className="container">
          <div className="row">
            <div className="col md-6 mt-3">
              <div className="stay_img">
                <img
                  src="/assets/images/newslatter-banner.svg"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col md-6 mt-3">
              <h3>Stay In The Know</h3>
              <h4>
                Subscribe to our weekly newsletter to get our latest news and
                advice.
              </h4>
              <div className="d-flex align-items-center gap-2  mt-4">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault1"
                  >
                    For Employers
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    defaultChecked
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault2"
                  >
                    For Remote Workers
                  </label>
                </div>
              </div>
              <p>
                Learn everything you need to know about building a profitable,{" "}
                <br />
                purpose-driven business with the help of remote workers From
                Latam.
              </p>
            
              <div className="search-worker pur-btn">
                <form
                  _ngcontent-c12
                  noValidate
                >
                  <input
                    _ngcontent-c12
                    email
                    name="email"
                    ngmodel
                    required
                    type="text"
                    placeholder="Your best email"
                  />
                  <button >
                    Subscribe
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer
        className="footer text-center text-lg-start text-white"
        style={{ backgroundColor: "#202533" }}
      >
        <div className="container">
          <div className="row list">
            <div className="col-md-3 col-12">
              <ul className="list-unstyled">
                <p>Company</p>
                <hr />
                <li>
                  <a href="#">About Us</a>
                </li>

               
                <li>
                  <a href="#">Contact Us</a>
                </li>
              </ul>
            </div>
            <div className="col-md-3 col-12">
              <ul className="list-unstyled">
                <p>Why VM</p>
                <hr />
                <li>
                  <a href="#">Why VM</a>
                </li>
                <li>
                  <a href="#">How Does VM Work</a>
                </li>
                <li>
                  <a href="#">More About Cost</a>
                </li>

              </ul>
            </div>
            <div className="col-md-3 col-12">
              <ul className="list-unstyled">
                <p>Policy</p>
                <hr />
                <li>
                  <a href="#">Refund Policy</a>
                </li>
                <li>
                  <a href="#">Privacy Policy</a>
                </li>
                <li>
                  <a href="#">User Agreement</a>
                </li>
              </ul>
            </div>
            <div className="col-md-3 col-12 icons px-4">
              <p>Connect with us</p>
              <hr />
              <ul className="icon_main list-unstyled d-flex justify-content-around">
                <li>
                  <a href="#">
                    <BsTwitter size={40} className="icon" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <GrFacebookOption size={40} className="icon2" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <GrLinkedinOption size={40} className="icon3" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <BsYoutube size={40} className="icon4" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <FaPinterestP size={40} className="icon5" />
                  </a>
                </li>
              </ul>
              <div>
                {/* <img
                  src="/assets/images/footer_img.png"
                  className="img-fluid"
                  alt=""
                /> */}
              </div>
            </div>
          </div>
          <hr />
          <div className="" style={{ color: "#b7b8bb" }}>
            <p className="bottom_txt">
              Copyright © 2023. All Rights Reserved | All content on this
              website belongs to Virtual Mate Private Limited
              and is copyright protected.
              <br />
              All client agreements shall be through Virtual Mate Pvt. Ltd.,
              India / TeckValley LLC, USA
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
