/** @format */

import React from "react";

const data = [
  {
    id: 1,
    image: "../assets/images/free-button.svg",
    head: "Free Trial",
    para: "Uncertain if we're the right fit? Test our caliber and expertise before making a commitment by giving us a trial.",
  },
  {
    id: 2,
    image: "../assets/images/new-employee.svg",
    head: "Dedicated Employees",
    para: "With VM, bid farewell to freelancing concerns as your resource works exclusively and dedicatedly for you.",
  },
  {
    id: 3,
    image: "../assets/images/employee-skills.svg",
    head: "Multiple Skills",
    para: "Recruit junior, mid-level, or senior Finance & Accounting experts and tap into the diverse experience and skills offered by our finance team.",
  },
  {
    id: 4,
    image: "../assets/images/communication.svg",
    head: "Easy Communication",
    para: "Stay seamlessly connected with your remote employee through our diverse communication channels, including Skype, email, telephone, and more.",
  },
  {
    id: 5,
    image: "../assets/images/letter.svg",
    head: "Zero Overheads",
    para: "From office rent, infrastructure to HR, accounts, and admin, VE takes care of everything for you.",
  },
  {
    id: 6,
    image: "../assets/images/office-building.svg",
    head: "Your Own Offshore Office",
    para: "With VM, it's not just outsourcing; instead, you get a world-class, fully supervised offshore extension of your local office right here in Pakistan.",
  },
];

const Benifits = () => {
  return (
    <>
      <div className='benifits_sec'>
        <div className='container'>
          <h2>Unlocking the Advantages: 6 Benefits of Outsourcing Your Finance Department to VM</h2>
          <h3>
          Virtual Mate Outsourced Finance and Accounting services team collectively brings over 50 years of specialized experience to assist you in all areas of the financial and accounting domain. As one of the most trusted and reputable finance outsourcing companies in Pakistan , Virtual Mate proudly boasts an impressive client base of 3021 clients spanning across 30 countries globally.
          </h3>
          <div className='row mt-5'>
            {data.map(res => {
              return (
                <div key={res.id} className='col-md-4 mt-5'>
                  <div className='img_main'>
                    <img src={res.image} alt='' />
                  </div>
                  <h4>{res.head}</h4>
                  <p>{res.para}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Benifits;
