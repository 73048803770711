/** @format */

import React from "react";
import ContactForms from "../components/Utils/ContactForms";

const GetStarted = () => {
  React.useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    scrollToTop();
  }, []);
  return (
    <div className='frontend_hero'>
      <div className='container'>
        <div className=' m-auto' style={{ maxWidth: 600 }}>
          <ContactForms />
        </div>
      </div>
    </div>
  );
};

export default GetStarted;
