/** @format */

import axios from "axios";
import React, { useState } from "react";

const ContactForms = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contactNumber: "",
    projectDuration: "Select Duration",
    website: "",
    workRequirement: "",
  });

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();

    if (validateForm()) {
      try {
        // Set submitting state
        setIsSubmitting(true);

        const response = await axios.post(
          "https://virtualmateio.onrender.com/submit",
          formData,
        );

        // Handle success
        console.log("Form submitted successfully:", response.data);

        // Display success message and reset the form
        setSuccessMessage("Thank you! We will get back to you soon.");
        setFormData({
          name: "",
          email: "",
          contactNumber: "",
          projectDuration: "Select Duration",
          website: "",
          workRequirement: "",
        });

        // Clear errors
        setErrors({});
      } catch (error) {
        // Handle error, e.g., show an error message to the user
        console.error("Error submitting form:", error.message);
      } finally {
        // Reset submitting state
        setIsSubmitting(false);
      }
    }
  };

  const handleDurationSelect = duration => {
    setFormData({ ...formData, projectDuration: duration });
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" }); // Clear validation errors on input change
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    // Add your validation logic here
    for (const field in formData) {
      if (!formData[field].trim()) {
        newErrors[field] = `${
          field.charAt(0).toUpperCase() + field.slice(1)
        } is required`;
        valid = false;
      }

      if (field === "email" && formData[field].trim()) {
        const emailRegex = /\S+@\S+\.\S+/;
        if (!emailRegex.test(formData[field])) {
          newErrors[field] = "Invalid email address";
          valid = false;
        }
      }
    

        // Validate Contact Number
        if (field === 'contactNumber' && formData[field].trim()) {
          const numberRegex = /^[0-9]+$/;
          if (!numberRegex.test(formData[field])) {
            newErrors[field] = 'Contact Number must contain only numbers';
            valid = false;
          }
        }
      }

    // Check if Project Duration is still the default value
    if (formData.projectDuration === "Select Duration") {
      newErrors.projectDuration = "Please select a duration";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  return (
    <div className='Hero-forms'>
      <h3>Share Your Requirement</h3>
      <form className='mt-4' onSubmit={handleSubmit}>
        <div className='d-flex gap-3 '>
          <div className='mb-3'>
            <label htmlFor='exampleInputPassword1' className='form-label'>
              Name<sup className='text-danger '>*</sup>
            </label>
            <input
              type='text'
              className={`form-control ${errors.name ? "is-invalid" : ""}`}
              placeholder='Full Name'
              name='name'
              value={formData.name}
              onChange={handleChange}
            />
            {errors.name && (
              <div className='invalid-feedback'>{errors.name}</div>
            )}
          </div>

          <div className='mb-3'>
            <label htmlFor='exampleInputEmail1' className='form-label'>
              E-mail Address<sup className='text-danger'>*</sup>
            </label>
            <input
              type='email'
              className={`form-control ${errors.email ? "is-invalid" : ""}`}
              placeholder='Enter Email Address'
              aria-describedby='emailHelp'
              name='email'
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && (
              <div className='invalid-feedback'>{errors.email}</div>
            )}
          </div>
        </div>

        <div className='d-flex gap-3 '>
          <div className='mb-3'>
            <label htmlFor='exampleInputPassword1' className='form-label'>
              Contact Number
            </label>
            <input
              type='text'
              className={`form-control ${
                errors.contactNumber ? "is-invalid" : ""
              }`}
              placeholder='Contact Number'
              name='contactNumber'
              value={formData.contactNumber}
              onChange={handleChange}
            />
            {errors.contactNumber && (
              <div className='invalid-feedback'>{errors.contactNumber}</div>
            )}
          </div>

          <div className='mb-3 '>
            <label htmlFor='exampleInputEmail1' className='form-label'>
              Project Duration<sup className='text-danger'>*</sup>
            </label>
            <div className='dropdown'>
              <button
                className={`btn dropdown-toggle ${
                  errors.projectDuration ? "is-invalid" : ""
                }`}
                type='button'
                id='dropdownMenuButton1'
                data-bs-toggle='dropdown'
                aria-expanded='false'>
                {formData.projectDuration}
              </button>

              <ul
                className='dropdown-menu'
                aria-labelledby='dropdownMenuButton1'>
                <li>
                  <span
                    className='dropdown-item'
                    onClick={() => handleDurationSelect("Less than 1 Month")}>
                    Less than 1 Month
                  </span>
                </li>
                <li>
                  <span
                    className='dropdown-item'
                    onClick={() => handleDurationSelect("1 to 3 Month")}>
                    1 to 3 Month
                  </span>
                </li>
                <li>
                  <span
                    className='dropdown-item'
                    onClick={() => handleDurationSelect("3 to 6 Month")}>
                    3 to 6 Month
                  </span>
                </li>
                <li>
                  <span
                    className='dropdown-item'
                    onClick={() => handleDurationSelect("6 to 12 Month")}>
                    6 to 12 Month
                  </span>
                </li>
                <li>
                  <span
                    className='dropdown-item'
                    onClick={() => handleDurationSelect("More then 1 Year")}>
                    More then 1 Year
                  </span>
                </li>
                <li>
                  <span
                    className='dropdown-item'
                    onClick={() => handleDurationSelect("Not Sure")}>
                    Not Sure
                  </span>
                </li>
                <li>
                  <span
                    className='dropdown-item'
                    onClick={() => handleDurationSelect("Looking for Job")}>
                    Looking for Job
                  </span>
                </li>
                {/* ... other duration options */}
              </ul>
              {errors.projectDuration && (
                <div className='invalid-feedback'>{errors.projectDuration}</div>
              )}
            </div>
          </div>
        </div>

        <div className='mb-3'>
          <label htmlFor='exampleInputPassword1' className='form-label'>
            Website<sup className='text-danger'>*</sup>
          </label>
          <input
            type='text'
            className={`form-control ${errors.website ? "is-invalid" : ""}`}
            placeholder='Website'
            value={formData.website}
            onChange={handleChange}
            name='website'
          />
          {errors.website && (
            <div className='invalid-feedback'>{errors.website}</div>
          )}
        </div>

        <div className='mb-3'>
          <label htmlFor='exampleInputPassword1' className='form-label'>
            Work Requirement<sup className='text-danger'>*</sup>
          </label>
          <div>
            <textarea
              className={`form-control ${
                errors.workRequirement ? "is-invalid" : ""
              }`}
              placeholder='Add Your Requirement'
              id='workRequirement'
              style={{ height: 100 }}
              value={formData.workRequirement}
              onChange={handleChange}
              name='workRequirement'
            />
            {errors.workRequirement && (
              <div className='invalid-feedback'>{errors.workRequirement}</div>
            )}
          </div>
        </div>

        <div className='btn_main'>
          <button
            type='submit'
            className='btn btn-primary'
            disabled={isSubmitting}>
            {isSubmitting ? "Submitting..." : "Submit Your Requirement"}
          </button>
        </div>
        {successMessage && (
          <div className='alert alert-success' role='alert'>
            {successMessage}
          </div>
        )}
      </form>
    </div>
  );
};

export default ContactForms;
