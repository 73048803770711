import React from 'react'

const Future = () => {
  return (
    <>
        <div className="future_sec">
            <div className="container">
            <div className="d-flex justify-content-center">
            <div className="border_top"></div>
          </div>
          <h2>The Evolution of Remote Tech Recruitment</h2>
            <div className="row pt-5">
                <div className="col-md-4 mt-3">
                    <h4>Strengths in our talent pool</h4>
                    <h3>500+</h3>
                </div>
                <div className="col-md-4 mt-3">
                    <h4>Typical matching time</h4>
                    <h3>2 days</h3>
                </div>
                <div className="col-md-4 mt-3">
                    <h4>Matching success rate</h4>
                    <h3>94%</h3>
                </div>
            </div>
            </div>
        </div>
    </>
  )
}

export default Future