import React from 'react'
import Hero from '../components/Elements/Block-Chain/Hero'
import Perform from '../components/Elements/Block-Chain/Perform'
import Services from '../components/Elements/Block-Chain/Services'
import BlockTabs from '../components/Elements/Block-Chain/BlockTabs'

const BlockChain = () => {
  React.useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    scrollToTop();
  }, []);
  return (
    <>
        <Hero/>
        <Perform/>
        <Services/>
        <BlockTabs/>
    </>
  )
}

export default BlockChain