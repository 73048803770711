import React from 'react'
import Hero from '../components/Elements/Data-Entry/Hero'
import Offshore from '../components/Elements/Data-Entry/Offshore'
import Services from '../components/Elements/Data-Entry/Services'
import Expert from '../components/Elements/Data-Entry/Expert'
import Industry from '../components/Elements/Data-Entry/Industry'

const DataEntry = () => {
  React.useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    scrollToTop();
  }, []);
  return (
    <>
        <Hero/>
        <Offshore/>
        <Services/>
        <Expert/>
        <Industry/>
    </>
  )
}

export default DataEntry