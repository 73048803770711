import React from 'react'
import ContactForms from '../../Utils/ContactForms'

const Hero = () => {
  return (
    <>
         <div className="frontend_hero">
            <div className="container">
                <div className="row">
                    <div className="col-md-7 d-flex flex-column justify-content-center ">
                        <h2>Hire Finance & Accounts Experts in Pakistan </h2>
                        <p> Starting at $7/Hour – Save 70%</p>
                        <h5>Outsource Financial Accounting Services to Certified Professionals</h5>
                        <ul className='front'>
                            <li>Comprehensive Coverage of Accounts and Taxation Services Worldwide </li>
                            <li>Benefit from 50+ Combined Years of Rich Experience in All Accounting Software</li>
                            <li>Our Finance Experts Have Generated Over 200,000 Invoices, and Counting</li>
                        </ul>
                   
                    </div>
                    <div className="col-md-5">
                    <ContactForms />

                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Hero