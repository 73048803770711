/** @format */

import React from "react";
import { Link } from "react-router-dom";

const Hiring = () => {
  return (
    <>
      <div className='hiring_sec'>
        <div className='container'>
          <div className='d-flex justify-content-center'>
            <div className='border_top'></div>
          </div>
          <h2>Simplifying Remote Staffing</h2>

          <div className='row mt-5'>
            <div className='col-md-4 mt-3'>
              <div className='card'>
                <div className='d-flex justify-content-center'>
                  <img
                    src='assets/images/branding_4646863.svg'
                    className='img-fluid'
                    style={{ width: "30%" }}
                    alt=''
                  />
                </div>
                <div className='absolute_img'>
                  <img
                    src='assets/images/c5.png'
                    className='img-fluid'
                    alt=''
                  />
                </div>
                <h5 className='my-4'>Share Your Needs</h5>
              </div>
            </div>
            <div className='col-md-4 mt-3'>
              <div className='card'>
                <div className='d-flex justify-content-center'>
                  <img
                    src='assets/images/assign_6208373.svg'
                    className='img-fluid'
                    style={{ width: "30%" }}
                    alt=''
                  />
                </div>
                <div className='absolute_img'>
                  <img
                    src='assets/images/c6.png'
                    className='img-fluid'
                    alt=''
                  />
                </div>
                <h5 className='my-4'>Select Your Team</h5>
              </div>
            </div>
            <div className='col-md-4 mt-3'>
              <div className='card'>
                <div className='d-flex justify-content-center'>
                  <img
                    src='assets/images/destination_4970014.svg'
                    className='img-fluid '
                    style={{ width: "30%" }}
                    alt=''
                  />
                </div>
                <div className='absolute_img'>
                  <img
                    src='assets/images/c7.png'
                    className='img-fluid'
                    alt=''
                  />
                </div>
                <h5 className='my-4'>Commence Your Journey</h5>
              </div>
            </div>
          </div>
          <div className='btn_main d-flex justify-content-center align-items-center py-5'>
            <Link to='get-started'>
              {" "}
              <button>Share Your Requirement </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hiring;
