import React from 'react'

const Hero = () => {
  return (
    <>
        <div className="block_hero_sec">
            <div className="container">
                <div className="row mt-5">
                    <div className="col-md-6 mt-3">
                        <h4>Ensure Data Quality & Integrity in Your Blockchain Eco system</h4>
                        <h2>Hire Blockchain Developers in Pakistan</h2>
                        <p>Starting from Just  <span>US $10/Hour</span></p>
                        <div className="btn_main">
                            <button>Hire a Blockchain Developer</button>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <img src="../assets/images/blockchain.png" className='img-fluid' alt="" />
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Hero