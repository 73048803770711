import React from 'react'
import Hero from '../components/Elements/Marketing/Hero'
import Media from '../components/Elements/Marketing/Media'
import Social from '../components/Elements/Marketing/Social'
import Benefits from '../components/Elements/Marketing/Benefits'
import Tools from '../components/Elements/Marketing/Tools'

const Marketing = () => {
  React.useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    scrollToTop();
  }, []);
  return (
    <>
        <Hero/>
        <Media/>
        <Social/>
        {/* <Benefits/>
        <Tools/> */}
    </>
  )
}

export default Marketing