/** @format */

import React from "react";
import { TiTick } from "react-icons/ti";

const Adventages = () => {
  return (
    <>
      <div className='about_adventage'>
        <div className='container'>
          <h2>Advantages of outsourcing with VM for SMEs</h2>
          <ul>
            <li>
              {/* <TiTick/> */}
              Partnering with VirtualMate.io is like having your own branch
              office in Pakistan, just like 50 percent of all 500 Fortune
              companies who have a strong presence in Pakistan.
            </li>
            <li>
              Solving problems you face with freelancing and at rates better
              than freelancing. In the freelancing model, every freelancer
              usually works remotely from a different location. This creates
              communication inconvenience between the entire team if the client
              has hired a virtual team of freelancers who are scattered all over
              the globe.
            </li>
            <li>
              A huge talent pool available at competitive cost working for you
              from your managed office in Pakistan – all at zero setup cost. But
              with VirtualMate.com, your entire team sits next to each other in
              a managed office. This increases the productivity of the team as a
              whole and makes it easier for the client to manage his/ her
              projects. The best part? The workforce/ office/ payroll/ hardware/
              software are all managed by the VirtualMate.com staff.
            </li>
          
          </ul>
        </div>
      </div>
    </>
  );
};

export default Adventages;
