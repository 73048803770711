import React from 'react'
import Hero from '../components/Elements/Content-Writing/Hero'
import Message from '../components/Elements/Content-Writing/Message'
import OutSource from '../components/Elements/Content-Writing/OutSource'
import Grid from '../components/Elements/Content-Writing/Grid'
import Cards from '../components/Elements/Content-Writing/Cards'
import Digital from '../components/Elements/Content-Writing/Digital'

const ContentWriting = () => {
  React.useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    scrollToTop();
  }, []);
  return (
    <>
        <Hero/>
        <Message/>
        <OutSource/>
        <Grid/>
        <Cards/>
        <Digital/>
    </>
  )
}

export default ContentWriting