import React from 'react'
import Hero from '../components/Elements/Digital-Marketing/Hero'
import Market from '../components/Elements/Digital-Marketing/Market'
import Client from '../components/Elements/Digital-Marketing/Client'
import Destination from '../components/Elements/Digital-Marketing/Destination'
import Hire from '../components/Elements/Digital-Marketing/Hire'
import Tools from '../components/Elements/Digital-Marketing/Tools'

const DigitalMarketing = () => {
  React.useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    scrollToTop();
  }, []);
  return (
    <>
        <Hero/>
        <Market/>
        {/* <Destination/> */}
        <Hire/>
        {/* <Tools/> */}
        {/* <Client/> */}
    </>
  )
}

export default DigitalMarketing