import React from "react";

const Work = () => {
  return (
    <>
      <div className="work_sec">
        <div className="container">
          <h2>Feeling Stuck Working More than 45 Hours a Week?</h2>
          <h4 className="text-center mt-3">
          Hire Virtual Assistants in Pakistan for the work that you should be missing…
          </h4>
          <div className="row mt-5">
            <div className="col-md-8 mt-4">
              <p>
              Are you finding yourself buried in emails, bogged down with hotel bookings, or swamped with social media posts, leaving little time for actual business growth or personal life? It's time for a transformative shift in your workday.
              </p>
              <p>
              Consider hiring a virtual assistant in Pakistan to delegate daily tasks and distractions. Let our VAs reduce your workload by up to 78%, handling personal assistant errands, administrative responsibilities, data entry, bookkeeping, accounting, customer support, and even digital marketing tasks.
              </p>
              <p>
              A dedicated virtual assistant is the key to addressing red flags you might have overlooked—missing paperwork, unanswered emails, client complaints about customer service, or negative reviews. Virtual Employee, with its team of virtual assistants in Pakistan, has a proven track record of helping companies get back on track.
              </p>
              <p>Moreover, if you're spending significant time on social media, blog creation, event or trip planning, or document creation, a remote virtual assistant can easily handle these tasks.</p>
              <p>At Virtual Employee, a leading virtual assistant company in Pakistan, you gain access to proactive virtual assistants who offer help without waiting to be asked. What sets them apart:</p>
          <ul>
            <li>British Council certified for English proficiency, ensuring no language barriers.</li>
            <li>VBA certified (Visual Basic), demonstrating expertise in Excel and spreadsheets.</li>
            <li>Lean Six Sigma Black Belt certified data specialists among our pool of Pakistani virtual assistants.</li>
            <li>Hire virtual assistants with Management Information System (MIS) expertise for comprehensive support</li>
          </ul>
            </div>
            <div className="col-md-4 mt-4 d-flex flex-column justify-content-center">
                <img src="../assets/images/virtual.jpg" className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Work;
