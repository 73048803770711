/** @format */

import React from "react";

const Media = () => {
  const [showMore, setShowMore] = React.useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };
  return (
    <>
      <div className='media_sec'>
        <div className='container'>
          <h5> Hire Social Media Experts from Pakistan </h5>
          <h2> Elevate Brand Awareness and Fuel Conversions </h2>
          <p>
            From driving growth to generating leads and fueling sales, social
            media holds the key to limitless possibilities for your brand. As
            the marketing landscape continues to shift from offline to online,
            social media marketing becomes an integral component of your brand
            journey.
          </p>
          <p>
            However, maintaining a team of dedicated social media marketing
            experts can be a costly venture, particularly for small or medium
            enterprises.
          </p>
          {showMore ? (
            <>
              <p>
                Outsourcing your social media marketing campaign to Virtual Mate
                can offer significant cost savings for your online marketing
                endeavors. At Virtual Mate, we also ensure that your brand
                remains prominent in the minds of your existing audience through
                timely tweets, Instagram-worthy images, informative LinkedIn
                posts, and more.
              </p>
              <p>
                Whether you operate as a B2B company or a B2C enterprise, our
                social media marketing experts in Pakistan will create tailored
                campaigns for your various social media channels. We'll assist
                you in increasing website traffic by sharing timely and engaging
                content, and enhance engagement by staying relevant and reducing
                response time on your social media posts
              </p>
            </>
          ) : null}

          {showMore ? (
            <div className='btn_main mt-5 d-flex justify-content-center align-items-center py-5'>
              <button onClick={toggleShowMore}>View Less Services</button>
            </div>
          ) : (
            <div className='btn_main mt-5 d-flex justify-content-center align-items-center py-5'>
              <button onClick={toggleShowMore}>View All Services</button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Media;
