import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

const Difference = () => {
  return (
    <>
      <div className="differ_sec">
        <div className="container">
          <h5>Our Designs that</h5>
          <h2>Made A Difference</h2>
          <Tabs>
            <TabList>
          <div className="d-flex justify-content-center align-items-center flex-wrap gap-3 mt-5">
            <Tab><button>Web Design</button></Tab>
            <Tab><button>Logo Design</button></Tab>
            <Tab>  <button>Graphic Design</button></Tab>
            <Tab><button>Photo Retouching</button></Tab>
            <Tab><button>Gaming</button></Tab>
          </div>
            </TabList>
            <TabPanel>
                <div className="row mt-4">
                    <div className="col-md-4">
                        <img src="../assets/images/t1.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-4">
                        <img src="../assets/images/t2.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-4">
                        <img src="../assets/images/t3.jpg" className="img-fluid" alt="" />
                    </div>
                </div>
            </TabPanel>
            <TabPanel>
            <div className="row mt-4">
                    <div className="col-md-4">
                        <img src="../assets/images/t4.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-4">
                        <img src="../assets/images/t5.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-4">
                        <img src="../assets/images/t6.jpg" className="img-fluid" alt="" />
                    </div>
                </div>
            </TabPanel>
            <TabPanel>
            <div className="row mt-4">
                    <div className="col-md-4">
                        <img src="../assets/images/t7.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-4">
                        <img src="../assets/images/t8.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-4">
                        <img src="../assets/images/t9.jpg" className="img-fluid" alt="" />
                    </div>
                </div>
            </TabPanel>
            <TabPanel>
            <div className="row mt-4">
                    <div className="col-md-4">
                        <img src="../assets/images/t10.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-4">
                        <img src="../assets/images/t11.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-4">
                        <img src="../assets/images/t12.jpg" className="img-fluid" alt="" />
                    </div>
                </div>
            </TabPanel>
            <TabPanel>
            <div className="row mt-4">
                    <div className="col-md-4">
                        <img src="../assets/images/t13.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-4">
                        <img src="../assets/images/t14.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-4">
                        <img src="../assets/images/t15.jpg" className="img-fluid" alt="" />
                    </div>
                </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default Difference;
