import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";   

const Portfolio = () => {
  return (
    <>
          <div className="differ_sec">
        <div className="container">
          <h5>Mobile Apps Portfolio</h5>
          <h2> Discover, Develop and Deliver Apps of Your Choice</h2>
          <Tabs>
            <TabList>
          <div className="d-flex justify-content-center align-items-center flex-wrap  mt-5">
            <Tab><button>Android</button></Tab>
            <Tab><button>iOS</button></Tab>
            <Tab>  <button>Windows</button></Tab>
            <Tab><button> Xamarin</button></Tab>
            <Tab><button> Mobile Game</button></Tab>
          </div>
            </TabList>
            <TabPanel>
                <div className="row mt-4">
                    <div className="col-md-4">
                        <img src="../assets/images/l1.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-4">
                        <img src="../assets/images/l2.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-4">
                        <img src="../assets/images/l3.png" className="img-fluid" alt="" />
                    </div>
                </div>
            </TabPanel>
            <TabPanel>
            <div className="row mt-4">
                    <div className="col-md-4">
                        <img src="../assets/images/l4.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-4">
                        <img src="../assets/images/l5.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-4">
                        <img src="../assets/images/l6.jpg" className="img-fluid" alt="" />
                    </div>
                </div>
            </TabPanel>
            <TabPanel>
            <div className="row mt-4">
                    <div className="col-md-4">
                        <img src="../assets/images/l7.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-4">
                        <img src="../assets/images/l8.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-4">
                        <img src="../assets/images/l9.jpg" className="img-fluid" alt="" />
                    </div>
                </div>
            </TabPanel>
            <TabPanel>
            <div className="row mt-4">
                    <div className="col-md-4">
                        <img src="../assets/images/l10.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-4">
                        <img src="../assets/images/l4.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-4">
                        <img src="../assets/images/l12.jpg" className="img-fluid" alt="" />
                    </div>
                </div>
            </TabPanel>
            <TabPanel>
            <div className="row mt-4">
                    <div className="col-md-4">
                        <img src="../assets/images/l13.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-4">
                        <img src="../assets/images/l14.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-4">
                        <img src="../assets/images/l15.jpg" className="img-fluid" alt="" />
                    </div>
                </div>
            </TabPanel>
       
        
          </Tabs>
        </div>
      </div>
    </>
  )
}

export default Portfolio