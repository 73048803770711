/** @format */

import React from "react";

const Hire = () => {
  return (
    <>
      <div className='hire_sec'>
        <div className='container'>
          <h5>Why Hire</h5>
          <h2>Dedicated Designers From VM</h2>
          <p>
            Our experience collaborating with clients from more than 30
            countries has provided us with a keen understanding of global trends
            and a diverse range of design languages.
          </p>
          <h4>Collaborating with VM offers you:</h4>
          <div className='row'>
            <div className='col-md-6 d-flex justify-content-center mt-4'>
              <ul>
                <li>Dedicated designers with domain proficiency</li>
                <li>Seamless communication and collaboration </li>
                <li>Agile and responsive design solutions</li>
                <li>Accountable project management</li>
              </ul>
            </div>
            <div className='col-md-6 d-flex justify-content-center mt-4'>
              <ul>
                <li>Scalable teams</li>
                <li>24X7 IT and administrative support </li>
                <li>An ISO 27001:2013 certified and CMMiL3 assessed company</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hire;
