/** @format */

import React from "react";

const Design = () => {
  return (
    <>
      <div className='design_sec'>
        <div className='container'>
          <h5>How Design Outsourcing</h5>
          <h2>Assists Your Business</h2>
          <p>
            Design strategies wield considerable potential in shaping a
            company's reputation. The integral aspects of branding, web
            development, and graphics consist of crucial design elements at
            every stage. It is absolutely vital to engage dedicated designers
            with the right experience, skills, and credibility.
          </p>
        </div>
      </div>
    </>
  );
};

export default Design;
