/** @format */

import React from "react";
import ContactForms from "../../Utils/ContactForms";

const Hero = () => {
  return (
    <>
      <div className='frontend_hero'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-7 d-flex flex-column justify-content-center '>
              <h2>Hire Automation Engineers </h2>
              <p>Starts from US $14/Hour — Save 70%</p>
              <h5>for High-Performance, Dynamic and Fast Bots</h5>
              <ul className='front'>
                <li>250+ developers with expertise in Automation</li>
                <li>
                  3500+ clients across 4 continents trust us with Automation
                </li>
                <li>Handpick and start with your developers within 8 hours</li>
              </ul>
            </div>
            <div className='col-md-5'>
              <ContactForms />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
