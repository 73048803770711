/** @format */

import React from "react";

const Lifting = () => {
  return (
    <>
      <div className='lifting_sec'>
        <div className='container'>
          <div className='d-flex justify-content-center'>
            <div className='border_top'></div>
          </div>
          <h2>Let Your Virtual Assistants Tackle the Tough Tasks.</h2>
          <p className='py-3'>
            Outsource Virtual Assistant services to Pakistan
          </p>
          <div className='row'>
            <div className='col-md-4 mt-3'>
              <div className='img_main'>
                <img
                  src='../assets/images/money.svg'
                  className='img-fluid'
                  alt='..'
                />
              </div>
              <h4>Massive Cost Saving</h4>
              <h5>
                Hiring a virtual assistant in Pakistan is a highly
                cost-effective solution, making it one of the primary reasons
                for consideration.
              </h5>
            </div>
            <div className='col-md-4 mt-3'>
              <div className='img_main'>
                <img
                  src='../assets/images/n1.svg'
                  className='img-fluid'
                  alt='..'
                />
              </div>
              <h4>Globally Recognized Soft Skills </h4>
              <h5>
                Enlist Pakistani virtual assistants for their exceptional time
                and project management, effective communication, and attentive
                listening skills.
              </h5>
            </div>
            <div className='col-md-4 mt-3'>
              <div className='img_main'>
                <img
                  src='../assets/images/n2.svg'
                  className='img-fluid'
                  alt='..'
                />
              </div>
              <h4>Time Zone Advantage</h4>
              <h5>
                Benefit from 24/7 Availability: Virtual assistant companies in
                Pakistan operate around the clock. Hire a personal assistant in
                Pakistan to handle all your same-day tasks efficiently.
              </h5>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Lifting;
