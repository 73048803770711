/** @format */

import React from "react";

const Sections = () => {
  return (
    <>
      <div className='section_bg'>
        <div className='container'>
          <div className='d-flex justify-content-center'>
            <div className='border_top'></div>
          </div>
          <h2>
            Why Opting for Virtual Team Members Is a Smart Business Decision
          </h2>
          <p className='py-5'>
            If you've been enthusiastic about saving 20% on costs with local
            remote hiring, imagine the potential savings when you hire Virtual
            Colleagues. Firstly, your cost savings skyrocket to an impressive
            70% (or even more, depending on the domain you hire in). Secondly,
            you establish a strong backup plan to ensure business resilience and
            continuity, regardless of lockdowns or unforeseen events.
          </p>

          <h4>Three primary benefits of a remote workforce:</h4>
          <div className='d-flex justify-content-center'>
            <img
              src='assets/images/border.svg'
              style={{ width: "20%" }}
              className='img-fluid'
              alt=''
            />
          </div>
          <div className='card_main'></div>
          <div className='row  my-4'>
            <div className='col-md-4 mt-3'>
              <div className='card'>
                <div className='d-flex justify-content-center mb-3'>
                  <img src='assets/images/money_7019230.svg' alt='' />
                </div>
                <h5>Cost Savings</h5>
                <p className='card_text'>
                Achieve financial efficiency through prudent spending
                </p>
              </div>
            </div>
            <div className='col-md-4 mt-3'>
              <div className='card'>
                <div className='d-flex justify-content-center mb-3'>
                  <img src='assets/images/goal_3201168.svg' alt='' />
                </div>
                <h5>Enhanced Resilience</h5>
                <p className='card_text'>
                Bolster your adaptability and resilience in the face of challenges
                </p>
              </div>
            </div>
            <div className='col-md-4 mt-3'>
              <div className='card'>
                <div className='d-flex justify-content-center mb-3'>
                  <img src='assets/images/cogwheel_3716569.svg' alt='' />
                </div>
                <h5>Business Continuity</h5>
                <p className='card_text'>
                Safeguard uninterrupted operations for lasting success
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sections;
