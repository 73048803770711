/** @format */

import React from "react";

const Animation = () => {
  return (
    <>
      <div className='expert_sec'>
        <div className='bgImg'>
          <div className='text_bg'>
            <h5>Hire Animation Experts for the Complete Range of</h5>
            <h2>Multimedia Animation Services</h2>
          </div>
        </div>
        <div className='container'>
          <div className='row mt-5'>
            <div className='col-md-4 mt-3'>
              <div className='card_img'>
                <img src='../assets/images/play.svg' alt='' />
              </div>
              <h4>Promotional Videos</h4>
              <p style={{ textAlign: "center" }}>
                Promotional videos encompass a variety of content types,
                including explainer videos, sales videos, whiteboard videos,
                presentations, introductions, and how-to videos
              </p>
            </div>
            <div className='col-md-4 mt-3'>
              <div className='card_img'>
                <img src='../assets/images/3d.svg' alt='' />
              </div>
              <h4>2D Animation</h4>
              <p style={{ textAlign: "center" }}>
                Recruit a skilled animation artist proficient in 2D animation,
                featuring expertise in motion graphics, sketching and
                storyboarding, stop-motion, and more.
              </p>
            </div>
            <div className='col-md-4 mt-3'>
              <div className='card_img'>
                <img src='../assets/images/e-learning.svg' alt='' />
              </div>
              <h4>E-learning Animation</h4>
              <p style={{ textAlign: "center" }}>
                Employ multimedia animators with expertise in e-learning
                animation, adept at creating tutorials, puzzle learning
                applications, and other educational content.
              </p>
            </div>
            <div className='col-md-4 mt-3'>
              <div className='card_img'>
                <img src='../assets/images/3dcub.svg' alt='' />
              </div>
              <h4>3D Animation</h4>
              <p style={{ textAlign: "center" }}>
                3D Animation serves diverse applications including modeling,
                dynamic simulations, films and YouTube videos, games and
                prototypes, 3D graphics, and 3D e-commerce software.
              </p>
            </div>
            <div className='col-md-4 mt-3'>
              <div className='card_img'>
                <img src='../assets/images/graphic-design.svg' alt='' />
              </div>
              <h4>Graphic Design</h4>
              <p style={{ textAlign: "center" }}>
                Graphic design plays a crucial role in creating logos, social
                media banners, T-shirts, flyers, leaflets, book covers,
                brochures, labels, posters, magazines, e-books, and
                infographics.
              </p>
            </div>
            <div className='col-md-4 mt-3'>
              <div className='card_img'>
                <img src='../assets/images/video-library.svg' alt='' />
              </div>
              <h4>Video Editing</h4>
              <p style={{ textAlign: "center" }}>
                Video editing services are employed for Matte painting,
                Rotoscopy, motion tracking, particle simulation, Chroma, and
                delivering special effects, among other applications.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Animation;
