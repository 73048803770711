import React from "react";



const Grid = () => {
  return (
    <>
      <div className="grid_sec">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <img
                src="../assets/images/contents-writer.webp"
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="col-md-7">
              <h2>“Absolutely!”</h2>
              <h3>
              The Mono-syllabic Response Every Business Craves from its Customers.
              </h3>
              <p>Adorable doesn't sell. Intelligent doesn't sell.</p>
              <p>
              What sells is compelling copywriting, that potent fusion of human psychology and marketing science articulated in the written word.
              </p>
              <b>
              With 700+ landing pages, 350+ infographics, 50+ ebooks, 50+ case studies, and 1000s of articles, blogs, and press releases in their portfolio, Virtual Mate’s adept content creators have assisted clients across the entire digital marketing spectrum who:
Struggled with feeble content (low engagement, poor rankings)

              </b>
              <ul>
                <li>Had unfavorable experiences with freelancers</li>
                <li>Invested thousands of dollars in poorly converting marketing campaigns</li>
                <li>Had no clue what to write on their brand-new websites</li>
                <li>This indicates that we don't merely write. We write to assist you in selling and generating revenue.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Grid;
