import React from 'react'
import ContactForms from '../../Utils/ContactForms'

const Hero = () => {
  return (
    <>
            <div className="frontend_hero">
            <div className="container">
                <div className="row">
                    <div className="col-md-7 d-flex flex-column justify-content-center ">
                        <h2> Hire Data Entry Experts from Pakistan  </h2>
                        <p>Starting from just US $5/hour - Save 70%</p>
                        <h5>Speed up your data processing tasks by 3X</h5>
                        <ul className='front'>
                            <li>99.5% accuracy in service-level agreement (SLAs) guaranteed
 </li>
                            <li>Six Sigma, VBA, British Council and GNIT certified pros </li>
                            <li>Avail 1-week free trial with your chosen expert </li>
                        </ul>
                    </div>
                    <div className="col-md-5">
                    <ContactForms />

                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Hero