/** @format */

import React from "react";

const Offshore = () => {
  return (
    <>
      <div className='offshore_sec'>
        <div className='container'>
          <h2>Why Hire Offshore Data Entry Experts from Pakistan</h2>
          <div className='d-flex justify-content-center'>
            <h5>Accurate data extraction. Faster work. Higher profit</h5>
          </div>
          <p>
            The 21st century has truly established the fact that data is the new
            oil. All the tech behemoths and the startups rely on effective and
            efficient data collection and its management to serve their users
            the content and services that they go looking for. No company—no
            matter what stage of its journey it is in—can be a success if it
            doesn’t effectively manage its data. And the first step towards
            effective management of data is to process it without errors.
          </p>
          <p>
            {" "}
            <strong>
              Virtual Mate’s Six Sigma certified data entry experts have a
              combined 1,000+ years of work experience in effectively handling
              data for over 1500+ clients over the last 10 years.
            </strong>
            They have handled over 50 TB of data for our clients across 33
            countries and six continents, making them a perfect data entry
            resource for your company.
          </p>
          <p>
            Virtual Mate, which is among Pakistan's leading data entry
            outsourcing companies, follows a rigorous selection criteria to make
            sure only the best qualified data entry experts are selected. VE
            follows a three-stage process to ensure that only Six Sigma, VBA,
            British Council, and GNIT certified professionals successfully pass
            our selection test.
          </p>
          <p>
            Hiring your data entry professionals from Virtual Mate will give you
            certainty for flawless data entry and management work without facing
            any language barriers. Pakistan produces a significant number of
            graduates each year which gives a massive talent pool incomparable
            to anywhere else in the world.
          </p>
          <p>
            Virtual Mate, a leading data entry service provider in Pakistan,
            has over 200 data entry Mate's for you to handpick from and start
            your journey to impeccable data entry work that expedites your work
            processes. Outsource data entry services with Virtual Mate in
            Pakistan and stay on top of your daily tasks without compromising on
            the quality of work. With VE, you get the chance to handpick quality
            data entry resources, according to their experience and specialized
            skills tailored to your job description.
          </p>
          <p>
            <strong>
              Here are 6 reasons why companies outsource data entry services to
              Virtual Mate in Pakistan:
            </strong>
          </p>
          <ul>
            <li>200+ data entry experts to choose from</li>
            <li>Experienced with CRM, MS Office, Google Drive, etc.</li>
            <li>50TB data successfully processed for 1,500+ clients</li>
            <li>Your data is in safe hands with stringent NDAs</li>
            <li>Hire one data entry expert or a team, whatever suits you</li>
            <li>Monitor your resource live and connect any time you wish</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Offshore;
