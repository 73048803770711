/** @format */

import React from "react";
import { Link } from "react-router-dom";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { IoIosArrowForward } from "react-icons/io";
import { Button, Dropdown } from "antd";
import { Menu } from "antd";

const items = [
  {
    key: "1",
    label: (
      <div className='d-flex justify-content-between align-items-center'>
        <Link
          className='nav_dropdown'
          rel='noopener noreferrer'
          to='/services/it-outsourcing'>
          IT Outsourcing
        </Link>
        {/* <IoIosArrowForward size={22} color="#191a1e"/> */}
      </div>
    ),
  },
  {
    key: "2",
    label: (
      <Link
        className='nav_dropdown'
        rel='noopener noreferrer'
        to='/services/social-media-marketing'>
        SMM (Social Media Marketing)
      </Link>
    ),
  },
  {
    key: "3",
    label: (
      <Link
        className='nav_dropdown'
        rel='noopener noreferrer'
        to='/services/content-writing'>
        Content Writing
      </Link>
    ),
  },
  {
    key: "4",
    label: (
      <Link
        className='nav_dropdown'
        rel='noopener noreferrer'
        to='/services/graphic-designer'>
        Web Designing & Graphics
      </Link>
    ),
  },
  {
    key: "5",
    label: (
      <Link
        className='nav_dropdown'
        rel='noopener noreferrer'
        to='/services/virtual-assistant'>
        Virtual Assistant
      </Link>
    ),
  },
  {
    key: "6",
    label: (
      <Link
        className='nav_dropdown'
        rel='noopener noreferrer'
        to='/services/data-entry'>
        Data Entry
      </Link>
    ),
  },
  {
    key: "7",
    label: (
      <Link
        className='nav_dropdown'
        rel='noopener noreferrer'
        to='/services/multimedia-animation'>
        Video Editing & Animation
      </Link>
    ),
  },
  {
    key: "8",
    label: (
      <Link
        className='nav_dropdown'
        rel='noopener noreferrer'
        to='/services/mobile-app-dev'>
        Mobile App Dev
      </Link>
    ),
  },
  {
    key: "9",
    label: (
      <Link
        className='nav_dropdown'
        rel='noopener noreferrer'
        to='/services/digital-marketing'>
        Digital Marketing
      </Link>
    ),
  },
  {
    key: "10",
    label: (
      <Link
        className='nav_dropdown'
        rel='noopener noreferrer'
        to='/services/finance-account'>
        Finance & Account
      </Link>
    ),
  },
  {
    key: "11",
    label: (
      <Link
        className='nav_dropdown'
        rel='noopener noreferrer'
        to='/services/smart-contract'>
        Smart Contract
      </Link>
    ),
  },
  {
    key: "12",
    label: (
      <Link
        className='nav_dropdown'
        rel='noopener noreferrer'
        to='/services/telegram-trading'>
        Telegram Trading Bot
      </Link>
    ),
  },
  {
    key: "13",
    label: (
      <Link
        className='nav_dropdown'
        rel='noopener noreferrer'
        to='/services/blockchain'>
        Blockchain
      </Link>
    ),
  },
  {
    key: "14",
    label: (
      <Link
        className='nav_dropdown'
        rel='noopener noreferrer'
        to='/services/game-studio'>
        Gaming Studio
      </Link>
    ),
  },
];

const Header = () => {
  return (
    <>
      <div className='d-flex justify-content-center align-items-center gap-2 py-3'>
        <div className='nav_top'>
          <BsFillEnvelopeFill className='icon' color='#06a9ef' />
          <Link>sales@virtualmate.io</Link>
        </div>
        <div className='nav_top2'>
          <img
            src='/assets/images/america.png'
            className='img-fluid'
            style={{ width: "18%" }}
            alt=''
          />
          <Link>(+1) xxxxxxxx</Link>
        </div>
        <div className='nav_top2'>
          <img
            src='/assets/images/canada.png'
            className='img-fluid'
            style={{ width: "18%" }}
            alt=''
          />
          <Link>(+1) xxxxxxxx</Link>
        </div>
        <div className='nav_top2'>
          <img
            src='/assets/images/uk.webp'
            className='img-fluid'
            style={{ width: "18%" }}
            alt=''
          />
          <Link>(+44) xxxxxxx</Link>
        </div>
      </div>

      <nav className='navbar  navbar-expand-lg ' id='header'>
        <div className='container-fluid'>
          <Link className='navbar-brand  d-flex' to='/'>
            <img
              src='/assets/logo.png'
              className='img-fluid mx-2'
              width={50}
              // style={{ width: "10%" }}
              alt='logo'
            />
            Virtual <span>Mate</span>
          </Link>
          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarSupportedContent'
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'>
            <span className='navbar-toggler-icon' />
          </button>
          <div className='collapse navbar-collapse' id='navbarSupportedContent'>
            <ul className='navbar-nav ms-auto text-center mb-2  mb-lg-0'>
              <li className='nav-item '>
                <Link className='nav-link ' aria-current='page' to='/'>
                  Home
                </Link>
              </li>
              <li className='nav-item'>
                <Link className='nav-link' to='/about'>
                  about us
                </Link>
              </li>
              <li className='nav-item'>
                <Dropdown menu={{ items }} placement='bottom'>
                  <Link className='nav-link' to='#'>
                    Services
                  </Link>
                </Dropdown>
              </li>
              {/* <li className='nav-item'>
                <Link className='nav-link' to='#'>
                  500+ testimonials
                </Link>
              </li>
              <li className='nav-item'>
                <Link className='nav-link' to='#'>
                  Resources
                </Link>
              </li>
              <li className='nav-item'>
                <Link className='nav-link' to='#'>
                  Contact us
                </Link>
              </li> */}
              <li className='nav-item py-1'>
                <Link to='get-started'>
                  {" "}
                  <button className='nav_btn'>Send Us Your Requirement</button>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
