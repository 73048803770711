import React from 'react'
import Faqs from '../../Utils/Faqs'

const Who = () => {
  return (
    <>
        <div className="who_about">
            <div className="container">
                <div>
                    <Faqs/>
                </div>
            </div>
        </div>
    </>
  )
}

export default Who