import React from "react";

const Work = () => {
  return (
    <>
      <div className="work_sec">
        <div className="container">
          <div className="d-flex justify-content-center">
            <div className="border_top"></div>
          </div>
          <h2>How the Process Unfolds</h2>
          <div className="row mt-5 main_card">
            <div className="col-md-6">
            <div className="content">
              <div className="wrapper" data-v-097722ea contentEditable="false">
                <div className="label" data-v-097722ea contentEditable="false">
                  <span data-v-097722ea contentEditable="false">
                    1
                  </span>
                </div>
                <span
                  className="line"
                  data-v-097722ea
                  contentEditable="false"
                />
              </div>
              <div className="left_txt">
                <h4>
                Connect with your exclusive personal matcher
                </h4>
                <p>
                Discuss your unique needs during a 25-minute call at your convenience. Questions? We're here to listen.
                </p>
              </div>
            </div>
            </div>
            <div className="col-md-6" style={{padding: '0px'}}>
                <div className="visual">

                <div className="right_img">
                    <img src="assets/images/w1.svg" className="img-fluid" alt="" />
                    <div className="top_img">
                        <img src="assets/images/p1.webp"  alt="" />
                    </div>
                </div>
                </div>
            </div>
          </div>
          <div className="row mt-5 main_card">
            <div className="col-md-6">
            <div className="content">
              <div className="wrapper" data-v-097722ea contentEditable="false">
                <div className="label" data-v-097722ea contentEditable="false">
                  <span data-v-097722ea contentEditable="false">
                    2
                  </span>
                </div>
                <span
                  className="line"
                  data-v-097722ea
                  contentEditable="false"
                />
              </div>
              <div className="left_txt">
                <h4>
                Receive your custom-matched developers
                </h4>
                <p>
                In just about 2 days, access a curated pool of developers, handpicked and ready to contribute to your projects.

                </p>
              </div>
            </div>
            </div>
            <div className="col-md-6" style={{padding: '0px'}}>
                <div className="visual">

                <div className="right_img">
                    <img src="assets/images/w2.svg" className="img-fluid" alt="" />
                    <div className="top_img2">
                        <img src="assets/images/p2.webp"  alt="" />
                    </div>
                </div>
                </div>
            </div>
          </div>
          <div className="row mt-5 main_card">
            <div className="col-md-6">
            <div className="content">
              <div className="wrapper" data-v-097722ea contentEditable="false">
                <div className="label" data-v-097722ea contentEditable="false">
                  <span data-v-097722ea contentEditable="false">
                    3
                  </span>
                </div>
              
              </div>
              <div className="left_txt">
                <h4>
                Attain excellence
                </h4>
                <p>
                Seamlessly integrate your new developer(s). Concerned about invoicing? Relax, we'll handle it, allowing you to concentrate on delivering exceptional results.
                </p>
              </div>
            </div>
            </div>
            <div className="col-md-6" style={{padding: '0px'}}>
                <div className="visual">

                <div className="right_img3">
                    <img src="assets/images/w3.svg" className="img-fluid" alt="" />
                    <div className="top_img3">
                        <img src="assets/images/p3.webp"  alt="" />
                    </div>
                </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Work;
