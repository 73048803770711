/** @format */

import React from "react";

const Professional = () => {
  return (
    <>
      <div className='professional_about'>
        <div className='container'>
          <h2>Our Driving Force: Professionalism</h2>
          <p>
            The driving force behind our rapid success, which today sees us
            serve more than 3500+ companies from 48 countries, is our mindset.
            VirtualMate.com’s mindset is focused solely on professionalism.
            Professionalism in all areas, be it customer support, HR,
            recruitment, office hardware, office infrastructure, or management.
            But, most importantly, professionalism by always staying true to our
            commitment to quality and cost beliefs.
          </p>
        </div>
      </div>
    </>
  );
};

export default Professional;
