/** @format */

import React from "react";

const data = [
  {
    id: 1,
    image: "../assets/images/e1.svg",
    title: "Articles & Blogs",
    para: "Enhance your organic rankings with well-crafted, authoritative, and unique blogs and articles on any topic. Proficient in online research, Virtual Mate's writers assist your website in addressing a variety of search queries.",
  },
  {
    id: 2,
    image: "../assets/images/e2.svg",
    title: "Website & SEO Content",
    para: "Receive keyword-driven content that satisfies Google, ensuring your website consistently ranks high on search engine results pages (SERPs).",
  },
  {
    id: 3,
    image: "../assets/images/e3.svg",
    title: "Social Media Content",
    para: "Generate excitement across major social media platforms such as Facebook, Instagram, LinkedIn, YouTube, Pinterest, and TikTok with concise, catchy, and relevant content.",
  },
  {
    id: 4,
    image: "../assets/images/e4.svg",
    title: "Landing Pages",
    para: "The success of your PPC campaigns hinges on conversions on your landing pages. Virtual Mate's writers persuade visitors to click the Call-To-Action button through sharp copywriting.",
  },
  {
    id: 5,
    image: "../assets/images/e5.svg",
    title: "Technical & Medical",
    para: "From technical blogs and engineering reviews to writing about medical processes and documenting surgical procedures, Virtual Mate's content writers excel even in highly niche fields",
  },
  {
    id: 6,
    image: "../assets/images/e6.svg",
    title: "Ebooks and Brochures",
    para: "Become an author as Virtual Mate's writers help you create your own ebook; get information-rich brochures and showcase eye-catching MS PowerPoint presentations with our wordsmiths.",
  },
  {
    id: 7,
    image: "../assets/images/e7.svg",
    title: "White Papers",
    para: "Receive deeply-researched, authoritative, and persuasive White Papers crafted exclusively by Virtual Mate's most experienced writers, whose rich expertise makes them perfect for this task.",
  },
  {
    id: 8,
    image: "../assets/images/e8.svg",
    title: "Press Releases & Newsletters",
    para: "Win over online media with newsworthy content from former journalists as part of Virtual Mate’s press release writing services.",
  },
  {
    id: 9,
    image: "../assets/images/e9.svg",
    title: "Speeches",
    para: "Illuminate an event and captivate your audience with a memorable, engaging speech written for maximum impact by Virtual Mate's clever wordsmiths.",
  },
  {
    id: 10,
    image: "../assets/images/travel-holiday.svg",
    title: "Travel & Lifestyle",
    para: "Entice visitors to your travel and lifestyle websites or blogs with tempting, inviting content that is engagingly written and informative.",
  },
  {
    id: 11,
    image: "../assets/images/book-education.svg",
    title: "Academic Curriculum & Courses",
    para: "Effortlessly create age-appropriate academic curriculum and courses. Watch the raw version of your study material transform into 'gold,' thanks to the writing skills of the Virtual Mate team.",
  },
  {
    id: 12,
    image: "../assets/images/document.svg",
    title: "Legal Content",
    para: "Virtual Mate's expert content writers are well-versed in international legal systems and can expertly handle all types of legal writing requirements.",
  },
  {
    id: 13,
    image: "../assets/images/cover.svg",
    title: "CVs & Cover Letters",
    para: "Make your CV stand out as Virtual Mate’s writers give it the 'voice' it deserves in a competitive job market; a neatly-written cover letter that enhances your image is the icing on the cake.    ",
  },
  {
    id: 14,
    image: "../assets/images/product-catalog.svg",
    title: "Product & Service Descriptions",
    para: "Ensure that your products and services, from furniture to fast food delivery, grab customer attention through attractively worded descriptions.",
  },
  {
    id: 15,
    image: "../assets/images/editing-pencil.svg",
    title: "Editing & Proofreading",
    para: "Professional editors, with a hawk's eye for spotting errors, can transform all your rough drafts into powerful, grammatically correct pieces of content.",
  },
  {
    id: 16,
    image: "../assets/images/book-p.svg",
    title: "Book Pitches",
    para: "Your pitch is as powerful as the manuscript of your book. Let your Virtual Mate content writer draft a proposition that will impress publishers.",
  },
  {
    id: 17,
    image: "../assets/images/science-boo.svg",
    title: "Fiction",
    para: "Imaginative and innovative, Virtual Mate's fiction writers help you captivate your readership by providing the perfect dose of creativity and language skills.",
  },
  {
    id: 18,
    image: "../assets/images/script-1601.svg",
    title: "Gaming/ Movie Scripts",
    para: "If you have a great movie idea or a winning gaming concept, Virtual Mate's content writers can craft a gripping, tasteful script that promises to turn it into a huge success.",
  },
];

const Cards = () => {
  const [showMore, setShowMore] = React.useState(false);
  const firstRow = data.slice(0, 6);
  const secondRow = data.slice(6, 18);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };
  return (
    <>
      <div className='card_sec'>
        <div className='container-fluid'>
          <h2>Comprehensive Content Creation Services</h2>
          <h5>A Virtual Mate Wordsmith for Any Content </h5>
          <p>
            From straightforward blogs and product descriptions to intricate,
            research-driven case studies and landing pages, our content creators
            at Virtual Mate can handle it all.
          </p>
          <div className='row mt-5'>
            {firstRow.map(res => {
              return (
                <div key={res.id} className='col-md-4 mt-3'>
                  <div className='card'>
                    <div className='card_img'>
                      <img src={res.image} className='img-fluid' alt='' />
                    </div>
                    <h4>{res.title}</h4>
                    <p>{res.para}</p>
                  </div>
                </div>
              );
            })}

            {showMore
              ? secondRow.map(res => {
                  return (
                    <div key={res.id} className='col-md-4 mt-3'>
                      <div className='card'>
                        <div className='card_img'>
                          <img src={res.image} className='img-fluid' alt='' />
                        </div>
                        <h4>{res.title}</h4>
                        <p>{res.para}</p>
                      </div>
                    </div>
                  );
                })
              : null}

            {showMore ? (
              <div className='btn_main mt-5 d-flex justify-content-center align-items-center py-5'>
                <button onClick={toggleShowMore}>View Less Services</button>
              </div>
            ) : (
              <div className='btn_main mt-5 d-flex justify-content-center align-items-center py-5'>
                <button onClick={toggleShowMore}>View All Services</button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Cards;
