/** @format */

import React from "react";
import Hero from "../components/Elements/Frontend/Hero";
import Notch from "../components/Elements/Frontend/Notch";
import Skills from "../components/Elements/Frontend/Skills";
import Service from "../components/Elements/Frontend/Service";
import Perform from "../components/Elements/Frontend/Perform";

const Services = () => {
  React.useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    scrollToTop();
  }, []);
  return (
    <>
      <Hero />
      <Perform />
      {/* <Notch /> */}
      <Skills />
      {/* <Service /> */}
    </>
  );
};

export default Services;
