import React from 'react'
import ContactForms from '../../Utils/ContactForms'

const Hero = () => {
  return (
    <>
        <div className="frontend_hero">
            <div className="container">
                <div className="row">
                    <div className="col-md-7 d-flex flex-column justify-content-center ">
                        <h2>Hire Dedicated Designers from Pakistan  </h2>
                        <p>Starting at US $8/Hour — Save 70%</p>
                        <h5>For Responsive, Agile, and Custom Design Solutions</h5>
                    </div>
                    <div className="col-md-5">
                    <ContactForms />

                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Hero