import React from 'react'
import Hero from '../components/Elements/Telegram-Trading/Hero'
import Hire from '../components/Elements/Telegram-Trading/Hire'
import Service from '../components/Elements/Telegram-Trading/Service'
import Dev from '../components/Elements/Telegram-Trading/Dev'

const TelegramTrading = () => {
  React.useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    scrollToTop();
  }, []); 
  return (
    <>
        <Hero/>
        <Hire/>
        <Service/>
        {/* <Dev/> */}
    </>
  )
}

export default TelegramTrading