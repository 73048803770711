/** @format */

import React from "react";

const data = [
  {
    id: 1,
    image: "../assets/images/group-svgrepo.svg",
    para: "Hands -on experience in Accounts, Taxation , Auditing and Financial Operations",
  },
  {
    id: 2,
    image: "../assets/images/idea-light-bulb.svg",
    para: "In-depth understanding of Direct Taxes, Indirect Taxes, and Tax Audit.",
  },
  {
    id: 3,
    image: "../assets/images/brainstorm.svg",
    para: "Robust comprehension of Forex trading, including continuous backtesting and setting stops.",
  },
  {
    id: 4,
    image: "../assets/images/graph-svgrepo.svg",
    para: "Proficient in analyzing financial data and preparing comprehensive financial reports, statements, and projections.",
  },
  {
    id: 5,
    image: "../assets/images/budget-cost.svg",
    para: "Proficient in short and long-term budgeting and forecasting, rolling budgets, and product-line profitability analysis.",
  },
  {
    id: 6,
    image: "../assets/images/budget-cost-svgrepo.svg",
    para: "Expertise in MS Office and Tally ERP 9, along with a strong foundation in finance, accounting, budgeting, and cost control principles.",
  },
];

const Skills = () => {
  return (
    <>
      <div className='skills_sec'>
        <div className='container'>
          <h2>Hire Financial Experts For All Skill Levels</h2>
          <div className='d-flex justify-content-center'>
            <h5>
              Opting for offshore financial services to Pakistan with VM ensures
              access to customized skill sets tailored to your specific needs.
            </h5>
          </div>
          <p>
            VM’s financial and accounting team comprises professionals at
            junior, mid-level, managerial, and senior managerial levels, capable
            of meeting a diverse range of financial requirements.
          </p>
          <div className='row mt-5'>
            {data.map(res => {
              return (
                <div className='col-md-4 mt-4'>
                  <div className='card'>
                    <div className='card_img'>
                      <img src={res.image} alt='' />
                    </div>
                    <h4>{res.para}</h4>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Skills;
