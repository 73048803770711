import React from "react";

const data = [
    {id :1,image: '../assets/images/car.svg', head: 'Automotive'},
    {id :2,image: '../assets/images/bag.svg', head: 'Consumer Products'},
    {id :3,image: '../assets/images/books.svg', head: 'Education'},
    {id :4,image: '../assets/images/bank.svg', head: 'Banking & Insurance'},
    {id :5,image: '../assets/images/invest.svg', head: 'Investment Management'},
    {id :6,image: '../assets/images/building.svg', head: 'Real Estate'},
    {id :7,image: '../assets/images/media.svg', head: 'Media'},
    {id :8,image: '../assets/images/technology.svg', head: 'Technology'},
    {id :9,image: '../assets/images/utilities.svg', head: 'Power & Utilities'},
    {id :10,image: '../assets/images/cashier-sale.svg', head: 'Retail, Wholesale & Distribution'},
    {id :11,image: '../assets/images/industrial-robot.svg', head: 'Industrial Products'},
    {id :12,image: '../assets/images/gold-ingots.svg', head: 'Mining & Metals'},
    {id :13,image: '../assets/images/gas-station.svg', head: 'Oil, Gas & Chemicals'},
    {id :14,image: '../assets/images/government.svg', head: 'Government'},
    {id :15,image: '../assets/images/defense.svg', head: 'Defense, Security & Justice'},
    {id :16,image: '../assets/images/transportation.svg', head: 'Transportation'},
    {id :17,image: '../assets/images/ecology.svg', head: 'Life Sciences'},
    {id :18,image: '../assets/images/communicationd.svg', head: 'Telecommunications'},
    {id :19,image: '../assets/images/construction.svg', head: 'Construction'},
    {id :20,image: '../assets/images/entertainment.svg', head: 'Entertainment'},
]

const Industry = () => {
  return (
    <>
      <div className="industry_sec">
        <div className="container">
          <h2> Industries Served by VE’s Data Entry Experts</h2>
          <div className="d-flex justify-content-center">
            <h5>Hire the right expert for your business</h5>
          </div>
            <div className="row mt-5">
                {data.map((res) => {
                    return(
                        <div key={res.id} className="col-md-3 mt-3">
                    <div className="card">
                        <div className="card_img">
                            <img src={res.image}   className="img-fluid" alt="" />
                        </div>
                        <h3>{res.head}</h3>
                    </div>
                </div>
                    )
                })}
                
            </div>
        </div>
      </div>
    </>
  );
};

export default Industry;
