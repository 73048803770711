/** @format */

import React from "react";

const Hero = () => {
  return (
    <>
      <div className='hero_sec'>
        <div className='container'>
          <h1>Feeling Overwhelmed by Your Workload?</h1>
          <h2>Hire Virtual Assistants </h2>
          <h3>To enjoy a 4-hour workweek</h3>
          <div className='py-5 d-flex justify-content-center align-items-center'>
            <h4>
              Starting from Just <span>USD $5</span> /hour
            </h4>
          </div>
          <div className='d-flex justify-content-center align-items-center  gap-2'>
            <div className='d-flex main_img justify-content-center align-items-center'>
              <img src='../assets/images/h1.svg' alt='' />
              <p> 2000+ Employees</p>
            </div>
            <div className='d-flex main_img justify-content-center  align-items-center'>
              <img src='../assets/images/h2.svg' alt='' />
              <p> 150+ Domains</p>
            </div>
            <div className='d-flex main_img justify-content-center  align-items-center'>
              <img src='../assets/images/h3.svg' alt='' />
              <p> Start Within 8 hours</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
