import React from "react";

const data = [
  {
    id: 1,
    image: "../assets/images/app.svg",
    head: "Financial Smart Contracts",
    para: "Facilitate and automate financial transactions, including payments, lending, and other monetary agreements.",
  },
  {
    id: 2,
    image: "../assets/images/smartphone2.svg",
    head: "Legal Smart Contracts",
    para: "Execute and enforce legal agreements, reducing the need for intermediaries in areas like real estate, contracts, and intellectual property.",
  },
  {
    id: 3,
    image: "../assets/images/k3.svg",
    head: "Supply Chain Smart Contracts",
    para: "Optimize and automate processes within the supply chain, ensuring transparency in tasks like tracking, verifying authenticity, and managing inventory.",
  },
  {
    id: 4,
    image: "../assets/images/mobile.svg",
    head: "Insurance Smart Contracts",
    para: "Automate insurance-related processes, including policy issuance, claims processing, and payouts, based on predefined conditions.",
  },
  {
    id: 5,
    image: "../assets/images/smartphone.svg",
    head: "Identity Smart Contracts",
    para: "Manage and validate digital identities securely, enhancing identity verification and reducing the risk of fraud.    ",
  },
  {
    id: 6,
    image: "../assets/images/k6.svg",
    head: "Tokenization Smart Contracts",
    para: "Create and manage tokens representing assets, enabling fractional ownership of real-world assets like real estate or commodities.",
  },
  {
    id: 7,
    image: "../assets/images/privatelocation.svg",
    head: "Escrow Smart Contracts",
    para: "Securely hold funds in escrow until predetermined conditions are met, facilitating trust in various transactions.",
  },
  {
    id: 8,
    image: "../assets/images/content-data.svg",
    head: "Governance Smart Contracts",
    para: "Enable decentralized decision-making within organizations through automated voting mechanisms and governance structures.",
  },
  {
    id: 9,
    image: "../assets/images/nft.svg",
    head: "Non-Fungible Token (NFT) Smart Contracts",
    para: "Power the creation and management of unique digital assets, such as digital art, collectibles, and in-game items.",
  },
  {
    id: 10,
    image: "../assets/images/signature.svg",
    head: "Multi-Signature Smart Contracts",
    para: "Require multiple cryptographic signatures to authorize and execute transactions, enhancing security in financial and governance processes.",
  },
  {
    id: 11,
    image: "../assets/images/bussiness.svg",
    head: "Renting and Leasing Smart Contracts",
    para: "Automate agreements related to renting or leasing assets, ensuring compliance with terms and conditions",
  },
  {
    id: 12,
    image: "../assets/images/betting.svg",
    head: "Gambling and Betting Smart Contracts",
    para: "Facilitate transparent and automated gambling and betting transactions, ensuring fairness and trust in the process.",
  },
];

const Services = () => {
  return (
    <>
      <div className="service_data_sec">
        <div className="container">
          <h2>360° Smart Contract Development Services</h2>
          <div className="d-flex justify-content-center">
            <h5 style={{ fontSize: "20px", width: "72%" }}>
            Harness the Power of Smart contract Development for  Transparency 

            </h5>
          </div>
       
          <div className="row mt-5">
            {data.map((res) => {
              return (
                <div className="col-md-4 mt-3 px-4">
                  <div className="card_img">
                    <img src={res.image} className="img-fluid" alt="" />
                  </div>
                  <h4>{res.head}</h4>
                  <p>{res.para}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
