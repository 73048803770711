/** @format */

import React from "react";

const Professional = () => {
  return (
    <>
      <div className='professional_sec'>
        <div className='bgImg'>
          <div className='text_bg'>
            <h5> What Professional Design Services</h5>
            <h2>Can Do For You</h2>
          </div>
        </div>
        <div className='container'>
          <p>
            To enhance your online and digital presence, it's essential to
            enlist design experts who embody creativity, uniqueness, and
            professionalism. Only professional design services can ensure
            customer satisfaction and expertise in all areas, such as SEO
            optimization, lead conversion, coding intricacies, and more. When
            you hire a dedicated designer from VM, you can look forward to:
          </p>

          <div className='row mt-5'>
            <div className='col-md-3 mt-3'>
              <div className='card'>
                <div className='card_img'>
                  <img
                    src='../assets/images/i1.svg'
                    className='img-fluid'
                    alt=''
                  />
                </div>
                <h3>Build your distinctive identity</h3>
                <h4>
                  Branded appearance in line with the latest industry trends
                </h4>
              </div>
            </div>
            <div className='col-md-3 mt-3'>
              <div className='card'>
                <div className='card_img'>
                  <img
                    src='../assets/images/i2.svg'
                    className='img-fluid'
                    alt=''
                  />
                </div>
                <h3>Dynamic designs</h3>
                <h4>
                  Customized and responsive design aligned with mobile devices
                </h4>
              </div>
            </div>
            <div className='col-md-3 mt-3'>
              <div className='card'>
                <div className='card_img'>
                  <img
                    src='../assets/images/i3.svg'
                    className='img-fluid'
                    alt=''
                  />
                </div>
                <h3>Great UI and UX</h3>
                <h4>
                  Enhanced customer engagement through easy navigation and
                  interface
                </h4>
              </div>
            </div>
            <div className='col-md-3 mt-3'>
              <div className='card'>
                <div className='card_img'>
                  <img
                    src='../assets/images/i4.svg'
                    className='img-fluid'
                    alt=''
                  />
                </div>
                <h3>Visibility across platforms</h3>
                <h4>
                  Enhanced online visibility through social media compatibility
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Professional;
