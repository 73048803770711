/** @format */

import React from "react";

const Market = () => {
  return (
    <>
      <div className='design_sec'>
        <div className='container'>
          <h5>Hire Digital Marketing Experts from Pakistan</h5>
          <h2> Boost your Marketing at full Throttle </h2>
          <p>
            Pakistan has consistently earned the top spot, with 80% of European
            and US companies choosing it as their preferred outsourcing
            destination. Opting for digital marketing services in Pakistan
            offers exceptional value for your investment and allows you to tap
            into the world's largest talent pool. Select your internet marketing
            expert from a nation globally recognized for its reliability.
          </p>
          <p>
            Virtual Mate, a leading outsourcing company for digital marketing in
            Pakistan, boasts a team of over 350 certified online marketers.
            Whether your goal is audience outreach, traffic increase, or revenue
            growth, Virtual Mate has seasoned internet marketing specialists
            ready to deliver the desired results for your business objectives.
          </p>
          <p>
            With VM, you have a myriad of options to hire dedicated offshore
            online marketers. Choose from a single digital marketing employee or
            assemble a dedicated team of internet marketing experts from
            Pakistan, offering a diverse skill set including a digital marketing
            manager, SEO executive, social media manager, content writer,
            designer, and researcher. Flexible hiring options include full-time,
            part-time, and ad-hoc arrangements.
          </p>
          <p>
            VM's rigorous selection process ensures that only the most skilled,
            qualified, and experienced internet marketing professionals are
            selected for your organizational needs. Our offshore digital
            marketing experts not only excel in securing top rankings for your
            website but also leverage the latest tools for analysis and progress
            tracking. Gain a competitive edge with VM and achieve increased
            leads, sales, and revenue for your business.
          </p>
        </div>
      </div>
    </>
  );
};

export default Market;
