/** @format */

import React from "react";

const Services = () => {
  return (
    <>
      <div className='hire_sec'>
        <div className='container'>
          <h5>Unique Gaming Solutions to Drive User Engagement</h5>
          <h2>360° Game Development Services</h2>

          <div className='row mt-5'>
            <div className='col-md-4 mt-3'>
              <div className='card_img d-flex justify-content-center'>
                <img
                  src='../assets/images/hyper.svg'
                  style={{ width: "25%" }}
                  alt=''
                />
              </div>
              <h4>Hyper casual Games</h4>
              <p
                style={{
                  textAlign: "justify",
                  lineHeight: "25px",
                  marginTop: "10px",
                }}>
                VM's Unity game developers in Pakistan swiftly create and launch
                hyper-casual games with a strong replay factor, maximizing
                revenue through in-app advertisements.
              </p>
            </div>
            <div className='col-md-4 mt-3'>
              <div className='card_img d-flex justify-content-center'>
                <img
                  src='../assets/images/multiplayer.svg'
                  style={{ width: "25%" }}
                  alt=''
                />
              </div>
              <h4>Multiplayer Game Development </h4>
              <p
                style={{
                  textAlign: "justify",
                  lineHeight: "25px",
                  marginTop: "10px",
                }}>
                Engage Unity3D programmers from Pakistan to oversee
                comprehensive multiplayer game development services, leveraging
                cutting-edge technology platforms for constructing scalable and
                immersive multiplayer environments.
              </p>
            </div>
            <div className='col-md-4 mt-3'>
              <div className='card_img d-flex justify-content-center'>
                <img
                  src='../assets/images/puzzle.svg'
                  style={{ width: "25%" }}
                  alt=''
                />
              </div>
              <h4>Puzzles & Interactive games</h4>
              <p
                style={{
                  textAlign: "justify",
                  lineHeight: "25px",
                  marginTop: "10px",
                }}>
                Looking to engage your audience's intellect? Our game developers
                in Pakistan are adept at crafting interactive puzzle games that
                challenge users with conceptual and logical problems.
              </p>
            </div>
            <div className='col-md-4 mt-3'>
              <div className='card_img d-flex justify-content-center'>
                <img
                  src='../assets/images/vr.svg'
                  style={{ width: "25%" }}
                  alt=''
                />
              </div>
              <h4>Virtual Reality Games</h4>
              <p
                style={{
                  textAlign: "justify",
                  lineHeight: "25px",
                  marginTop: "10px",
                }}>
                Empower your gaming aspirations with our game development
                services in Pakistan, allowing you to craft interactive 3D
                gaming environments and develop immersive VR games tailored for
                diverse platforms.
              </p>
            </div>
            <div className='col-md-4 mt-3'>
              <div className='card_img d-flex justify-content-center'>
                <img
                  src='../assets/images/augmented-reality.svg '
                  style={{ width: "25%" }}
                  alt=''
                />
              </div>
              <h4>Augmented Reality Games</h4>
              <p
                style={{
                  textAlign: "justify",
                  lineHeight: "25px",
                  marginTop: "10px",
                }}>
                Recall the impact of Pokémon Go? Our game developers have the
                expertise to create similar AR games, transforming your
                surroundings into a digital interface through the placement of
                virtual objects in the real world.
              </p>
            </div>
            <div className='col-md-4 mt-3'>
              <div className='card_img d-flex justify-content-center'>
                <img
                  src='../assets/images/route-perspective.svg'
                  style={{ width: "25%" }}
                  alt=''
                />
              </div>
              <h4>Simulation Development</h4>
              <p
                style={{
                  textAlign: "justify",
                  lineHeight: "25px",
                  marginTop: "10px",
                }}>
                VM's game developers can effortlessly construct digital replicas
                of intricate environments through a 3D simulator, incorporating
                features like headless rendering and optimized runtimes.
              </p>
            </div>

            <div className='col-md-4 mt-3'>
              <div className='card_img d-flex justify-content-center'>
                <img
                  src='../assets/images/game0r.svg'
                  style={{ width: "25%" }}
                  alt=''
                />
              </div>
              <h4>2D/3D Game Development</h4>
              <p
                style={{
                  textAlign: "justify",
                  lineHeight: "25px",
                  marginTop: "10px",
                }}>
                Engage Unity game developers for leveraging advanced game engine
                tools in crafting games that seamlessly support both 2D and 3D
                interactions, ensuring a world-class gaming experience.
              </p>
            </div>
            <div className='col-md-4 mt-3'>
              <div className='card_img d-flex justify-content-center'>
                <img
                  src='../assets/images/game-sv.svg'
                  style={{ width: "25%" }}
                  alt=''
                />
              </div>
              <h4>Game Optimization </h4>
              <p
                style={{
                  textAlign: "justify",
                  lineHeight: "25px",
                  marginTop: "10px",
                }}>
                Enlist the expertise of Unity Game developers from Pakistan to
                analyze and address performance bottlenecks, including issues
                like slow game loading, latency, and significant spikes,
                ensuring a seamless and optimized gaming experience.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
