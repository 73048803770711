/** @format */

import React from "react";
import ContactForms from "../../Utils/ContactForms";

const Hero = () => {
  return (
    <>
      <div className='frontend_hero'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-7 d-flex flex-column justify-content-center '>
              <h2>
                {" "}
                Engage Your Audience with <br /> Expert Social Media Marketing{" "}
              </h2>
              <p>
                Hire Social Media Marketing Experts Starting at $8/Hour – Save
                70%
              </p>
              <h5>Attract, Engage, and Delight Your Audience</h5>
              <ul className='front'>
                <li>50 Dedicated Social Media Account Managers</li>
                <li>Successfully Handling 1500+ Social Media Accounts</li>
                <li>
                  Launching 4000+ Organic and Paid Campaigns for Optimal Results
                </li>
              </ul>
            </div>
            <div className='col-md-5'>
            <ContactForms />

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
