import React from 'react'

const Perform = () => {
  return (
    <>
        <div className="perform_sec">
            <div className="container">
                <h4>VM are synonymous with high performance and efficiency</h4>
                <h2> a fact substantiated by our numerical data.</h2>
                <div className="row mt-5">
                    <div className="col-md-3 mt-3">
                        <div className="img_main">
                            <img src="../assets/images/inho-dev.png" className='img-fluid' alt="" />
                        </div>
                        <h5>150+</h5>
                        <p>Blockchain Developers</p>
                    </div>
                    <div className="col-md-3 mt-3">
                        <div className="img_main">
                            <img src="../assets/images/suc-project.png" className='img-fluid' alt="" />
                        </div>
                        <h5>1200+</h5>
                        <p>Successful Projects</p>
                    </div>
                    <div className="col-md-3 mt-3">
                        <div className="img_main">
                            <img src="../assets/images/global-client.png" className='img-fluid' alt="" />
                        </div>
                        <h5>450+</h5>
                        <p>Satisfied Global Clients</p>
                    </div>
                    <div className="col-md-3 mt-3">
                        <div className="img_main">
                            <img src="../assets/images/year-exp-1.png" className='img-fluid' alt="" />
                        </div>
                        <h5>12</h5>
                        <p>Years’ Industry Experience</p>
                    </div>
                 
                </div>
            </div>
        </div>
    </>
  )
}

export default Perform