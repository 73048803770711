import React from 'react'

const Hero = () => {
  return (
    <>
        <div className="about_hero">
            <div className="container">
                <h2>About US</h2>
            </div>
        </div>
    </>
  )
}

export default Hero