import React from 'react'
import Hero from '../components/Elements/Game-Studio/Hero'
import Hire from '../components/Elements/Game-Studio/Hire'
import Services from '../components/Elements/Game-Studio/Services'
import Support from '../components/Elements/Game-Studio/Support'
import Benifit from '../components/Elements/Game-Studio/Benifit'

const GameStudio = () => {
  React.useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    scrollToTop();
  }, []);
  return (
    <>
        <Hero/>
        <Hire/>
        <Services/>
        {/* <Support/>
        <Benifit/> */}
    </>
  )
}

export default GameStudio