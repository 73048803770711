/** @format */

import React from "react";

const data = [
  {
    id: 1,
    image: "../assets/images/smart-contracts.svg",
    head: "Smart Contract Development",
    para: "Expert creation and deployment of smart contracts tailored to specific business needs.",
  },
  {
    id: 2,
    image: "../assets/images/k2.svg",
    head: "Decentralized Application (DApp) Development",
    para: "Design and development of decentralized applications for various industries.",
  },
  {
    id: 3,
    image: "../assets/images/cryptocurrency.svg",
    head: "Cryptocurrency Development",
    para: "Customization and launch of cryptocurrencies based on different blockchain platforms.",
  },
  {
    id: 4,
    image: "../assets/images/optimization-svgrepo-com.svg",
    head: "Blockchain Consulting",
    para: "Expert guidance on blockchain implementation, integration, and optimization strategies.",
  },
  {
    id: 5,
    image: "../assets/images/code-website-svgrepo-com.svg",
    head: "Tokenization Solutions",
    para: "Creation and management of tokenized assets for enhanced liquidity and ownership",
  },
  {
    id: 6,
    image: "../assets/images/k6.svg",
    head: "Consensus Mechanism Integration",
    para: "Implementation of secure and efficient consensus algorithms for blockchain networks."
  },
  {
    id: 7,
    image: "../assets/images/private-service.svg",
    head: "Private Blockchain Development",
    para: "Creation of private and permissioned blockchains tailored for enterprise use.",
  },
  {
    id: 8,
    image: "../assets/images/audits.svg",
    head: "Blockchain Security Audits",
    para: "Thorough examination and identification of security vulnerabilities in blockchain systems.",
  },
  {
    id: 9,
    image: "../assets/images/relay.svg",
    head: "Interoperability Solutions",
    para: "Integration of interoperability protocols to facilitate seamless communication between different blockchains.",
  },
  {
    id: 10,
    image: "../assets/images/supply-chain.svg",
    head: "Supply Chain Blockchain Implementation",
    para: "Utilization of blockchain for transparency and traceability in supply chain management.",
  },

];

const Services = () => {
  return (
    <>
      <div className='block_secrvice_sec'>
        <div className='container'>
          <h4>Manage Massive Blockchain Data Streams with Expertise</h4>
          <h2>
            360<sup>o</sup> Blockchain Services
          </h2>
          <div className='row mt-5'>
            {data.map(res => {
              return (
                <div className='col-md-4 mt-5 px-3 '>
                  <div className='card'>
                    <div className='card_img'>
                      <img src={res.image} className='img-fluid' alt='' />
                    </div>
                    <h3>{res.head}</h3>
                    <p>{res.para}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
