/** @format */

import React from "react";

const Digital = () => {
  return (
    <>
      <div className='digital_sec'>
        <div className='container'>
          <h2>
            The Secret Recipe for Building a Potent Sales Funnel with Content
          </h2>
          <h5>
            Effective Copywriting Strategies by Virtual Mate's Content Writers
          </h5>
        </div>
      </div>
      <div className='bottom_card'>
        <div className='container'>
          <div className='card_main'>
            <div className='card'>
              <div className='d-flex align-items-center card_inner gap-5'>
                <div className='card_img'>
                  <img
                    src='../assets/images/a1.svg'
                    className='img-fluid'
                    alt=''
                  />
                </div>
                <div>
                  <h1>Persuasive Copywriting for Conversions</h1>
                  <p>
                    Virtual Mate's content writers master the art of
                    transforming visitor hesitation into a resounding "Yes!"
                    Their expertise lies in crafting words and phrases that
                    resonate with the human brain, compelling visitors to click
                    the 'buy' button.
                  </p>
                </div>
              </div>
            </div>
            <div className='card'>
              <div className='d-flex align-items-center card_inner gap-5'>
                <div className='card_img'>
                  <img
                    src='../assets/images/a2.svg'
                    className='img-fluid'
                    alt=''
                  />
                </div>
                <div>
                  <h1>Compelling Value Propositions</h1>
                  <p>
                    To secure a positive response, Virtual Mate's writers focus
                    on developing a strong and compelling value proposition for
                    your offerings. This strategy makes your products or
                    services irresistible, setting you apart from competitors
                    and capturing customer interest.
                  </p>
                </div>
              </div>
            </div>
            <div className='card'>
              <div className='d-flex align-items-center card_inner gap-5'>
                <div className='card_img'>
                  <img
                    src='../assets/images/a3.svg'
                    className='img-fluid'
                    alt=''
                  />
                </div>
                <div>
                  <h1>Data-Driven Marketing Science</h1>
                  <p>
                    Employing data-driven, scientific marketing strategies,
                    Virtual Mate's offshore content writers delve into the
                    psychology of human motivation. By incorporating these
                    insights into their content, they ensure your material is
                    not just informative but also strategically designed for
                    successful conversions.
                  </p>
                </div>
              </div>
            </div>

            <div className='card'>
              <div className='d-flex align-items-center card_inner gap-5'>
                <div className='card_img'>
                  <img
                    src='../assets/images/reading-a-book-learn.svg'
                    className='img-fluid'
                    alt=''
                  />
                </div>
                <div>
                  <h1> Understanding Buyer Personas</h1>
                  <p>
                    Virtual Mate's professional content writers thoroughly
                    analyze your target audience to understand their
                    preferences, desires, and pain points. By addressing these
                    aspects in the copy, they create content that resonates with
                    your ideal customers, increasing the likelihood of
                    engagement and conversion.
                  </p>
                </div>
              </div>
            </div>

            <div className='card'>
              <div className='d-flex align-items-center card_inner gap-5'>
                <div className='card_img'>
                  <img
                    src='../assets/images/perspective-dice-one.svg'
                    className='img-fluid'
                    alt=''
                  />
                </div>
                <div>
                  <h1>Strategic Storytelling</h1>
                  <p>
                    Recognizing that stories sell better than mere facts,
                    Virtual Mate's content writing experts skillfully integrate
                    the art of storytelling into their content. This approach
                    enhances engagement, capturing the audience's attention and
                    mitigating the risk of a high bounce rate.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Digital;
