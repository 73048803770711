/** @format */

import React from "react";
import ContactForms from "../../Utils/ContactForms";

const Hero = () => {
  return (
    <>
      <div className='frontend_hero'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-7 d-flex flex-column justify-content-center '>
              <h2>
                {" "}
                Boost Your Business with <br /> Digital Marketing Prowess!{" "}
              </h2>
              <p>
                Unlock Success with Expertise Starting at $8/Hour - A 70%
                Savings
              </p>
              <h5>
                Hire Digital Marketing Specialists for SEO, PPC, CRO, and
                Beyond. Drive Growth, Increase Visibility – Your Path to Online
                Excellence!"
              </h5>
            </div>
            <div className='col-md-5'>
            <ContactForms />

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
