/** @format */

import React from "react";

const data = [
  {
    id: 1,
    image: "../assets/images/g1.svg",
    head: "Payroll",
    para: "Virtual Mate's payroll processing specialists excel in a range of services, including payroll general ledger reporting, payroll tax services, payroll administration, paymaster payroll services, job costing analysis, payment processing, and more.",
  },
  {
    id: 2,
    image: "../assets/images/g2.svg",
    head: "Tax Preparation",
    para: "Our tax preparation experts specialize in a range of services, including online tax preparation, VAT return consulting, sales tax services, sales tax return services, taxation accounting, corporation income taxes, and more.",
  },
  {
    id: 3,
    image: "../assets/images/g3.svg",
    head: "Accounting",
    para: "Covering Invoice processing, Accounts payables and receivables, Collections, Cash application, Month-end closing processes, and Reconciliation, our Accounting experts are available for a diverse range of key processes",
  },
  {
    id: 4,
    image: "../assets/images/g4.svg",
    head: "Bookkeeping",
    para: "Proficient in the latest software used in the bookkeeping trade, Virtual Mate bookkeeping experts specialize in a range of services, including custom reports, employee reports, break-up of expenses, general bookkeeping, bookkeeping clean-up, trial balance services, and more.",
  },
  {
    id: 5,
    image: "../assets/images/g5.svg",
    head: "Financial Analysis",
    para: "Access a range of financial services including financial research, financial analysis, corporate financial statements, monthly and annual reports, analysis of portfolio structure, financial ratio analysis, horizontal analysis, vertical analysis, and short-term analysis from our expert team.",
  },
  {
    id: 6,
    image: "../assets/images/g6.svg",
    head: "Financial Management",
    para: "Maximize your advantages with services such as interpreting financial reports, monitoring and interpreting cash flows and predicting future trends, formulating strategic and long-term business plans, and analyzing competitors and market trends.",
  },
];

const Services = () => {
  return (
    <>
      <div className='service_finance'>
        <div className='bg_img'></div>
        <div className='text_sec'>
          <h2> 360° Finance & Accounting Services</h2>
          <div className='d-flex justify-content-center'>
            <h5>
            Experience Financial Agility and Freedom with Our Comprehensive Suite of Finance and Accounting Services.
            </h5>
          </div>
        </div>
        <div className='container'>
          <div className='row mt-5'>
            {data.map(res => {
              return (
                <div key={res.id} className='col-md-4 mt-5'>
                  <div className='img_main'>
                    <img src={res.image} alt='' />
                  </div>
                  <h4>{res.head}</h4>
                  <p>{res.para}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div className='bg_img2'></div>
      </div>
    </>
  );
};

export default Services;
