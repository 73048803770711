/** @format */

import React from "react";
import { BsFillPlayFill } from "react-icons/bs";
import { Link } from "react-router-dom";

const Resource = () => {
  return (
    <>
      <div className='resource_sec'>
        <div className='container'>
          <div className='d-flex justify-content-center'>
            <div className='border_top'></div>
          </div>
          <h2>Virtual Colleague, Authentic Engagement</h2>
          <p className='py-5'>
            The skilled Virtual Partner you bring on board from VM is merely a
            mouse click away. Overcoming the geographical separation and time
            zone variations, Virtual Partner ensures collaborating with your
            resource feels as effortless as working in adjacent spaces.
          </p>

          <div className='row py-5'>
            <div className='col-md-5'>
              <img src='assets/images/h4.png' alt='' />
              <h3>
                They are genuine team members, <br /> just behind a video
                display
              </h3>
              <p className='right_text'>
                Larry Spencer,{" "}
                <span>Vice President at Sceris, United States</span>
              </p>
            </div>
            <div className="col-md-1"></div>
            <div className='col-md-6'>
              <div className='position-relative'>
                <iframe
                 className="w-100 rounded-3 "
                  height={355}
                  src='https://www.youtube.com/embed/3tbdh4VeB5g?si=l5O0eclf6nLvFkJD?autoplay=1&amp;rel=0&amp;showinfo=0&amp#!;'
                  title='YouTube video player'

                  frameBorder={0}
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                  allowFullScreen=''
                />

                {/* <img src='assets/images/r1.png' className='img-fluid' alt='' />
                <a
                  href='https://youtu.be/3tbdh4VeB5g?autoplay=1&amp;rel=0&amp;showinfo=0&amp#!;'
                  className='icon'>
                  <BsFillPlayFill color='#fff' />
                </a> */}
              </div>
            </div>
          </div>
        </div>
        <img src='assets/images/bottom_img.png' className='img-fluid' alt='' />
      </div>
    </>
  );
};

export default Resource;
