import React from 'react'
import Hero from '../components/Elements/Mobile-Dev/Hero'
import Business from '../components/Elements/Mobile-Dev/Business'
import Services from '../components/Elements/Mobile-Dev/Services'
import Portfolio from '../components/Elements/Mobile-Dev/Portfolio'

const MobileDev = () => {
  React.useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    scrollToTop();
  }, []);
  return (
    <>
        <Hero/>
        <Business/>
        <Services/>
        <Portfolio/>
    </>
  )
}

export default MobileDev