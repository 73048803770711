/** @format */

import React from "react";

const Perform = () => {
  return (
    <>
      <div className='perform_sec'>
        <div className='container'>
          <h4>Connect the Dots Between Traditional IT and Innovation</h4>
          <h2> Riding the Wave of Our Rapid Growth</h2>
          <div className='row mt-5'>
            <div className='col-md-3 mt-3'>
              <div className='img_main'>
                <img
                  src='../assets/images/inho-dev.png'
                  className='img-fluid'
                  alt=''
                />
              </div>
              <h5>Over 150</h5>
              <p>IT & IT-Enabled Services (ITES)</p>
            </div>
            <div className='col-md-3 mt-3'>
              <div className='img_main'>
                <img
                  src='../assets/images/suc-project.png'
                  className='img-fluid'
                  alt=''
                />
              </div>
              <h5>In-House Developers</h5>
              <p>A Strong Team of 750+ Certified IT Professionals</p>
            </div>
            <div className='col-md-3 mt-3'>
              <div className='img_main'>
                <img
                  src='../assets/images/global-client.png'
                  className='img-fluid'
                  alt=''
                />
              </div>
              <h5>Triumphant Projects</h5>
              <p>Over 5000 Successful Ventures</p>
            </div>
            <div className='col-md-3 mt-3'>
              <div className='img_main'>
                <img
                  src='../assets/images/year-exp-1.png'
                  className='img-fluid'
                  alt=''
                />
              </div>
              <h5>Global Client Icon</h5>
              <p>An Impressive 98.4% Client Retention Rate</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Perform;
