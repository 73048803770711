import React from 'react'
import Hero from '../components/Elements/MultiMedia/Hero'
import Grow from '../components/Elements/MultiMedia/Grow'
import Animation from '../components/Elements/MultiMedia/Animation'
import Hire from '../components/Elements/MultiMedia/Hire'
import Expert from '../components/Elements/MultiMedia/Expert'

const Multimedia = () => {
  React.useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    scrollToTop();
  }, []);
  return (
    <>
        <Hero/>
        <Grow/>
        <Animation/>
        <Hire/>
        <Expert/>
    </>
  )
}

export default Multimedia