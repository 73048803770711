/** @format */

import React, { useState } from "react";
import { Link } from "react-router-dom";

const Services = () => {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };
  return (
    <>
      <div className='services_sec'>
        <div className='container'>
          <div className='d-flex justify-content-center'>
            <div className='border_top'></div>
          </div>
          <h2>Complete Virtual Workforce Solutions</h2>
          <p className='py-3'>
            Bring on a remote team member in
            <em className='px-1'>
              <u>any</u>{" "}
            </em>
            field you need.
          </p>
          <div className='row  my-4'>
            <div className='col-md-3 services mt-5 '>
            <Link to='services/smart-contract'>
              <div className='d-flex justify-content-center'>
                <img
                  src='assets/images/smart-contracts.svg'
                  className='img-fluid'
                  alt=''
                />
              </div>
              <h4>smart contracts</h4>
              </Link>
            </div>
            <div className='col-md-3 services mt-5'>
              <Link to='services/it-outsourcing'>
                <div className='d-flex justify-content-center'>
                  <img
                    src='assets/images/people.svg'
                    className='img-fluid'
                    alt=''
                  />
                </div>
                <h4>IT outsourcing</h4>
              </Link>
            </div>
            <div className='col-md-3 services mt-5'>
            <Link to='services/graphic-designer'>
              <div className='d-flex justify-content-center'>
                <img src='assets/images/ux.svg' className='img-fluid' alt='' />
              </div>
              <h4>web designing & graphics</h4>
              </Link>
            </div>
            <div className='col-md-3 services mt-5'>
            <Link to='services/mobile-app-dev'>
              <div className='d-flex justify-content-center'>
                <img
                  src='assets/images/mobile-app.svg'
                  className='img-fluid'
                  alt=''
                />
              </div>
              <h4>mobile app dev</h4>
              </Link>
            </div>
            <div className='col-md-3 services mt-5'>
            <Link to='services/content-writing'>
              <div className='d-flex justify-content-center'>
                <img
                  src='assets/images/content-writing.svg'
                  className='img-fluid'
                  alt=''
                />
              </div>
              <h4>content writing</h4>
              </Link>
            </div>
            <div className='col-md-3 services mt-5'>
            <Link to='services/data-entry'>
              <div className='d-flex justify-content-center'>
                <img
                  src='assets/images/copywriter.svg'
                  className='img-fluid'
                  alt=''
                />
              </div>
              <h4>data entry</h4>
              </Link>
            </div>
            <div className='col-md-3 services mt-5'>
            <Link to='services/digital-marketing'>
              <div className='d-flex justify-content-center'>
                <img
                  src='assets/images/digital-marketing.svg'
                  className='img-fluid'
                  alt=''
                />
              </div>
              <h4>digital marketing</h4>
              </Link>
            </div>
            <div className='col-md-3 services mt-5'>
            <Link to='services/multimedia-animation'>
              <div className='d-flex justify-content-center'>
                <img
                  src='assets/images/montage.svg'
                  className='img-fluid'
                  alt=''
                />
              </div>
              <h4>video editing & animation</h4>
              </Link>
            </div>

            {showMore ? (
              <>
                <div className='col-md-3 services mt-4'>
                <Link to='services/virtual-assistant'>
                  <div className='d-flex justify-content-center'>
                    <img
                      src='assets/images/assistant.svg'
                      style={{ width: "34%" }}
                      className='img-fluid'
                      alt=''
                    />
                  </div>
                  <h4>virtual assistant</h4>
                  </Link>
                </div>
                <div className='col-md-3 services mt-5'>
                <Link to='services/finance-account'>
                  <div className='d-flex justify-content-center'>
                    <img
                      src='assets/images/budget.svg'
                      className='img-fluid'
                      alt=''
                    />
                  </div>
                  <h4>finance & account</h4>
                  </Link>
                </div>
                <div className='col-md-3 services mt-5'>
                  <div className='d-flex justify-content-center'>
                    <img
                      src='assets/images/nft.svg'
                      className='img-fluid'
                      alt=''
                    />
                  </div>
                  <h4>NFTs Marketplace</h4>
                </div>
                <div className='col-md-3 services mt-5'>
                <Link to='services/telegram-trading'>
                  <div className='d-flex justify-content-center'>
                    <img
                      src='assets/images/bot.svg'
                      className='img-fluid'
                      alt=''
                    />
                  </div>
                  <h4>Telegram Trading Bot</h4>
                  </Link>
                </div>
                <div className='col-md-3 services mt-5'>
                <Link to='services/blockchain'>
                  <div className='d-flex justify-content-center'>
                    <img
                      src='assets/images/blockchain.svg'
                      className='img-fluid'
                      alt=''
                    />
                  </div>
                  <h4>Blockchain</h4>
                  </Link>
                </div>
                <div className='col-md-3 services mt-5'>
                <Link to='services/game-studio'>
                  <div className='d-flex justify-content-center'>
                    <img
                      src='assets/images/game.svg'
                      className='img-fluid'
                      alt=''
                    />
                  </div>
                  <h4>Gaming Studio</h4>
                  </Link>
                </div>

                <div className='col-md-3 services mt-5'>
                  <Link to='services/social-media-marketing'>
                    <div className='d-flex justify-content-center'>
                      <img
                        src='assets/images/social-media.svg'
                        className='img-fluid'
                        alt=''
                      />
                    </div>
                    <h4>SMM (Social Media Marketing)</h4>
                  </Link>
                </div>
                {/* <div className='col-md-3 services mt-5'>
                  <div className='d-flex justify-content-center'>
                    <img
                      src='assets/images/s15.svg'
                      className='img-fluid'
                      alt=''
                    />
                  </div>
                  <h4>MS Office</h4>
                </div> */}
              </>
            ) : null}
          </div>
          {showMore ? (
            <div className='btn_main d-flex justify-content-center align-items-center py-5'>
              <button onClick={toggleShowMore}>View Less Services</button>
            </div>
          ) : (
            <div className='btn_main d-flex justify-content-center align-items-center py-5'>
              <button onClick={toggleShowMore}>View All Services</button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Services;
