import React from 'react'
import Hero from '../components/Elements/Smart-Contract/Hero'
import OutSource from '../components/Elements/Smart-Contract/OutSource'
import Services from '../components/Elements/Smart-Contract/Services'
import Tool from '../components/Elements/Smart-Contract/Tool'

const SmartContract = () => {
  React.useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    scrollToTop();
  }, []);
  return (
    <div>
        <Hero/>
        <OutSource/>
        <Services/>
        <Tool/>
    </div>
  )
}

export default SmartContract