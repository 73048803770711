/** @format */

import React from "react";
import { TiTick } from "react-icons/ti";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const data = [
  {
    id: 1,
    image: "assets/images/car1.webp",
    head: "Jezuina K.",
    para: " Machine Learning Engineer",
    span1: "TensorFlow",
    span2: "Machine learning",
  },
  {
    id: 2,
    image: "assets/images/car2.webp",
    head: "Doruk G.",
    para: "  DevOps Engineer",
    span1: "Python",
    span2: "Django",
    span3: "AWS",
  },
  {
    id: 3,
    image: "assets/images/car3.webp",
    head: "Yavuz C.",
    para: "  Backend Developer",
    span1: ".NET",
    span2: "C#",
    span3: "MSSQL",
  },
  {
    id: 4,
    image: "assets/images/car4.webp",
    head: "Nurullah A.",
    para: " Frontend Developer",
    span1: "React.js",
    span2: "Angular",
    span3: "Javascript",
  },
  {
    id: 5,
    image: "assets/images/car5.webp",
    head: "Khadija N.",
    para: " PHP Developer",
    span1: "PHP",
    span2: "Symfony",
    span3: "Vue.js",
  },
  {
    id: 6,
    image: "assets/images/car6.webp",
    head: "Esteban M.",
    para: " Fullstack Developer",
    span1: "JavaScript",
    span2: "Flutter",
    span3: "Node.js",
  },
];

const Cards = () => {
  return (
    <>
      <div className='hero_card_sec'>
        <div className='container'>
          <h2>
          The quickest route to recruit remote developers perfectly suited for your enterprise
          </h2>
          <p>
          Craft your squad with carefully selected developers from Proxify, starting at just €31.90 per hour.
          </p>
          <div className='d-flex justify-content-center align-items-center flex-wrap gap-3'>
            <div className='btn_1'>
              <button>find a developer</button>
            </div>
            <div className='btn_2'>
              <button>talk to an expert</button>
            </div>
            <div>
              <img src='assets/images/hero1.svg' className='img-fluid' alt='' />
            </div>
          </div>
          <div className='d-flex justify-content-center align-items-center flex-wrap gap-3 mt-4'>
            <div>
              <TiTick size={20} color='#74e4a4' />
              <small>500+ competencies</small>
            </div>
            <div>
              <TiTick size={20} color='#74e4a4' />
              <small>94% match success</small>
            </div>
            <div>
              <TiTick size={20} color='#74e4a4' />
              <small>2 days average matching time</small>
            </div>
          </div>
          <Carousel
            responsive={responsive}
            arrows={true}
            autoPlay={true}
            autoPlaySpeed={3000}
            draggable
            showArrows={true}
            focusOnSelect={false}
            infinite={true}
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            showDots={false}>
            {data.map(res => {
              return (
                <div className='card mt-5'>
                  <div className='card_img'>
                    <img src={res.image} className='img-fluid' alt='' />
                  </div>
                  <div className='card_text'>
                    <h4>{res.head}</h4>
                    <h5> {res.para}</h5>
                    <span>{res.span1}</span>
                    <span>{res.span2}</span>
                    {res.span3 ? <span>{res.span3}</span> : null}
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    </>
  );
};

export default Cards;
