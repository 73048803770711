/** @format */

import React from "react";
import ContactForms from "../../Utils/ContactForms";

const Hero = () => {
  return (
    <>
      <div className='frontend_hero'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-7 d-flex flex-column justify-content-center '>
              <h2>Engage Content Creator </h2>
              <p>Pricing begins at US $9/Hour — Unlock 70% Savings</p>
              <ul className='front'>
                <li>Access 50+ globally certified writers</li>
                <li>1500 words daily per writer</li>
                <li>
                  Receive 2 complimentary writing samples before making a
                  decision
                </li>
              </ul>
            </div>
            <div className='col-md-5'>
            <ContactForms />

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
