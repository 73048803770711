/** @format */

import React from "react";

const Technology = () => {
  return (
    <>
      <div className='technology_sec'>
        <div className='container'>
          <h2>Great combination of technology and human resources</h2>
          <div className='card'>
            <div className='row'>
              <div className='col-md-8 m-auto'>
                <h3>Virtual Mate Goal</h3>
                <h4>Same Quality at Low Cost</h4>
                <p>
                  VirtualMate.io, established in August 2007, has at its core a
                  unique and revolutionary business principle, ‘Same quality but
                  significantly lower cost’. It aims to fulfill the
                  long-standing outsourcing vacuum felt by scores of Small
                  Medium Enterprises (SMEs) across North America and Europe who,
                  till now, were dependent mostly on offshore freelancers.
                  VirtualMate.io simplifies their outsourcing experience by
                  providing them with dedicated Pakistani employees who work
                  exclusively for them just like their own in-house hires. These
                  Pakistani professionals match their western counterparts in
                  skills, qualifications, and experience along with the added
                  advantage of attractive low costs. In short, VirtualMate.io
                  provides equally (or even more) qualified employees from any
                  domain or profession but at significantly lower costs.
                </p>
              </div>
              <div className='col-md-4 d-flex justify-content-center align-items-center'>
                <img
                  src='assets/images/goals_10605943.svg'
                  className='img-fluid w-50'
                  alt=''
                />
              </div>
            </div>
          </div>
          <div className='card mt-4'>
            <div className='row'>
              <div className='col-md-4 d-flex justify-content-center align-items-center'>
                <img
                  src='assets/images/lightning_9056204.svg'
                  className='img-fluid w-50'
                  alt=''
                />
              </div>
              <div className='col-md-8 m-auto'>
                <h3>Virtual Mate Strength</h3>
                <h4>Intelligent Use of Technology and Human Resource</h4>
                <p>
                  We provide every client with a dedicated, full-time virtual
                  employee who works remotely for them from our office here in
                  Pakistan. To successfully achieve this objective,
                  VirtualMate.io relies on management, infrastructure, hardware,
                  and the latest technology to bridge physical distance and time
                  zone differences. Although your virtual employee works
                  directly for you, it is VirtualMate.io that provides every
                  employee with the latest hardware, infrastructure of
                  international standards and supervision, guidance, and
                  management. What this achieves is that even though your
                  employee is working from our office in Pakistan, they work
                  directly for you as though they were operating physically from
                  your office.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Technology;
