import React from 'react'
import ContactForms from '../../Utils/ContactForms'

const Hero = () => {
  return (
    <>
         <div className="frontend_hero">
            <div className="container">
                <div className="row">
                    <div className="col-md-7 d-flex flex-column justify-content-center ">
                        <h2> Hire Mobile <br />  App Developers    </h2>
                        <p>Starting from US $10/Hour — Save 70%</p>
                        <h5>Let Your Ideas Reach the App Stores 3X Faster</h5>
                   
                    </div>
                    <div className="col-md-5">
                    <ContactForms />

                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Hero