/** @format */

import React from "react";

const data = [
  {
    id: 1,
    image: "../assets/images/gambling.svg",
    head: "Custom Telegram Trading Bot Development",
    para: "Tailored creation of trading bots to automate specific trading strategies.",
  },
  {
    id: 2,
    image: "../assets/images/sort-swap.svg",
    head: "Algorithmic Trading Solutions",
    para: "Implementation of algorithms for the automated execution of predefined trading rules.",
  },
  {
    id: 3,
    image: "../assets/images/risk-assessment.svg",
    head: " Risk Management Integration",
    para: "Incorporation of risk management features to enhance the security of automated trading processes.",
  },
  {
    id: 4,
    image: "../assets/images/interface-ui.svg",
    head: "User-Friendly Interface Design",
    para: "Creation of intuitive interfaces for seamless interaction with trading bots on the Telegram platform.",
  },
  {
    id: 5,
    image: "../assets/images/monitoring.svg",
    head: "Continuous Monitoring and Optimization",
    para: "Ongoing monitoring and optimization of automated trading algorithms to adapt to market changes.",
  },
  {
    id: 6,
    image: "../assets/images/analytics.svg",
    head: "Data Analytics Integration",
    para: "Integration of data analytics for deriving insights and improving the performance of trading strategies.",
  },
  {
    id: 7,
    image: "../assets/images/notification.svg",
    head: "Notification Systems Implementation",
    para: "Implementation of notification systems to keep users informed about bot activities and market conditions.",
  },
  {
    id: 8,
    image: "../assets/images/settings-cogwheel.svg",
    head: "Customization for Specific Preferences",
    para: "Tailoring automation solutions to meet specific trading preferences and objectives.",
  },
  {
    id: 9,
    image: "../assets/images/collaboration.svg",
    head: "Client Collaboration",
    para: "Collaborative engagement with clients to understand unique trading requirements for personalized solutions.",
  },
  {
    id: 10,
    image: "../assets/images/in-progress.svg",
    head: "Ongoing Support and Maintenance",
    para: "Providing continuous support, maintenance, and updates to ensure the efficiency and reliability of automated trading systems",
  },
];

const Service = () => {
  return (
    <>
      <div className='service_finance'>
        <div className='bg_img'></div>
        <div className='text_sec'>
          <h2> 360° Automation Services </h2>
          <div className='d-flex justify-content-center'>
            <h5>
              Hire dedicated Automation engineers to create powerful, elegant
              and interactive Bots
            </h5>
          </div>
        </div>
        <div className='container'>
          <div className='row mt-5'>
            {data.map(res => {
              return (
                <div key={res.id} className='col-md-4 mt-5'>
                  <div className='img_main'>
                    <img src={res.image} alt='' />
                  </div>
                  <h4>{res.head}</h4>
                  <p>{res.para}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div className='bg_img2'></div>
      </div>
    </>
  );
};

export default Service;
